// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.room-manage {
  display: flex;
  flex-direction: column;
  min-height: 500px;
}
.room-manage .room-list-wrap {
  max-height: 60vh;
  overflow-y: auto;
  flex-grow: 1;
}
.room-manage .room-list-wrap .room-list > .ant-collapse-item > .ant-collapse-header {
  background-color: #eee;
}
.room-manage .room-list-wrap .room-list .ant-collapse-content-box {
  padding: 16px 6px;
}
.room-manage .room-actions {
  margin-top: 10px;
  display: flex;
  align-items: center;
}
.room-manage .room-actions .room-options-btn {
  border: 0;
  font-weight: 700;
}
.room-manage .room-actions .room-actions-list {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
}
.room-manage .room-actions .room-actions-list .ant-btn {
  margin-left: 5px;
}
.room-manage .room-closing-coutdown {
  padding: 20px 0;
  background: #ffc80f;
  border-radius: 4px;
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.room-manage .room-closing-coutdown-leave-btn {
  margin-top: 5px;
}`, "",{"version":3,"sources":["webpack://./src/feature/subsession/component/subsession-manage.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,iBAAA;AACF;AAAE;EACE,gBAAA;EACA,gBAAA;EACA,YAAA;AAEJ;AAAM;EACE,sBAAA;AAER;AAAM;EACE,iBAAA;AAER;AAEE;EACE,gBAAA;EACA,aAAA;EACA,mBAAA;AAAJ;AACI;EACE,SAAA;EACA,gBAAA;AACN;AACI;EACE,YAAA;EACA,aAAA;EACA,yBAAA;AACN;AAAM;EACE,gBAAA;AAER;AAEE;EACE,eAAA;EACA,mBAAA;EACA,kBAAA;EACA,WAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AAAJ;AACI;EACE,eAAA;AACN","sourcesContent":[".room-manage {\n  display: flex;\n  flex-direction: column;\n  min-height: 500px;\n  .room-list-wrap {\n    max-height: 60vh;\n    overflow-y: auto;\n    flex-grow: 1;\n    .room-list {\n      &> .ant-collapse-item > .ant-collapse-header{\n        background-color: #eee;\n      }\n      .ant-collapse-content-box {\n        padding: 16px 6px;\n      }\n    }\n  }\n  .room-actions{\n    margin-top: 10px;\n    display: flex;\n    align-items: center;\n    .room-options-btn {\n      border: 0;\n      font-weight: 700;\n    }\n    .room-actions-list {\n      flex-grow: 1;\n      display: flex;\n      justify-content: flex-end;\n      .ant-btn {\n        margin-left: 5px;\n      }\n    }\n  }\n  .room-closing-coutdown {\n    padding:20px 0;\n    background: #ffc80f;\n    border-radius: 4px;\n    color: #333;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    &-leave-btn {\n      margin-top: 5px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
