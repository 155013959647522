import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Swal from 'sweetalert2'; // Import SweetAlert2 for notifications
import apiService from '../services/api';

const InviteModel = ({ showModal, handleClose }: any) => {
  const [availableDoctors, setAvailableDoctors] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loadingDoctorId, setLoadingDoctorId] = useState<number | null>(null); // State to track loading doctor ID

  const fetchAvailableDoctors = async () => {
    try {
      const GetAvailDoctors = await apiService.GetDoctors();
      console.log(GetAvailDoctors.data, 'GetAvailDoctors');
      setAvailableDoctors(GetAvailDoctors.data);
    } catch (error) {
      console.error('Error fetching Doctors:', error);
    }
  };

  useEffect(() => {
    if (showModal) {
      fetchAvailableDoctors();
    }
  }, [showModal]);

  // Filter doctors based on the search query
  const filteredDoctors = availableDoctors.filter((doctor: any) => {
    const nameMatch = doctor.name.toLowerCase().includes(searchQuery.toLowerCase());
    const specialtyMatch = doctor.specialty.some((specialty: any) =>
      specialty.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    return nameMatch || specialtyMatch; // Matches if either name or specialty includes the search query
  });
  

  const InviteDoctor = async (doctor: any) => { 
    try {
      setLoadingDoctorId(doctor.id); // Set loading state for the clicked doctor
      const urlParameter = new URLSearchParams(window.location.search);
      const callID = urlParameter.get('callID') ?? '';
      await apiService.InviteDoc(doctor, callID);
      
      // Show success message
      Swal.fire({
        position: "top",
        icon: "success",
        title: "The doctor has been successfully invited",
        showConfirmButton: false,
        timer: 5000, // Auto-close after 5 seconds
        customClass: {
          popup: 'small-swal'
        }
      });
      
      setLoadingDoctorId(null); // Reset loading state
      handleClose(); // Close modal after success
    } catch (error) {
      console.error('Error inviting doctor:', error);
      setLoadingDoctorId(null); // Reset loading state
      Swal.fire({
        position: "top",
        icon: "error",
        title: "Error inviting doctor. Please try again.",
        showConfirmButton: false,
        timer: 5000, // Auto-close after 5 seconds
        customClass: {
          popup: 'small-swal'
        }
      });
    }
  };
  

  return (
    <div
      className={`modal fade ${showModal ? 'show' : ''}`}
      style={{ display: showModal ? 'block' : 'none' }}
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden={!showModal}
    >
      <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Invite Doctors</h5>
            <button type="button" className="close" onClick={handleClose} aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body" style={{ height: '600px', overflowY: 'auto' }}>
            {/* Search Input */}
            <div className="mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Search by name or specialty"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            {/* Filtered Doctor List */}
            {filteredDoctors.length > 0 ? (
              filteredDoctors.map((doctor: any) => (
                <div key={doctor.id} className="doctor-card mb-3 p-3 border rounded">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <div className="doctor-image">
                        <img
                          src={doctor.profile_picture || '/images/users.png'}
                          crossOrigin="anonymous"
                          className="rounded-circle"
                          alt="profile"
                          style={{ width: '60px', height: '60px', borderRadius: '50%' }}
                        />
                      </div>
                      <div className="doctor-info flex-grow-1 mx-3">
                        <h5 className="mb-1">{doctor.name}</h5>
                        <small className="text-muted">
              {doctor.specialty && doctor.specialty.length > 0 ? doctor.specialty[0].name : 'No Specialty'}
            </small>
                      </div>
                    </div>
                    <div className="doctor-invite">
                      <button
                        className="btn p-3 w-5 fs-6 fw-normal"
                        style={{ color: 'white', backgroundColor: 'var(--theme-bg)' }}
                        onClick={() => InviteDoctor(doctor)}
                        disabled={loadingDoctorId === doctor.id} // Disable button while loading
                      >
                        {loadingDoctorId === doctor.id ? 'Inviting...' : 'Invite'}
                      </button>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No doctors available to invite.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InviteModel;
