import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import apiService from './services/api';

interface User {
  display_name: string;
  name: string;
  gender: string;
  dob: string;
  ssno: string;
  profile_picture: string;
  email: string;
  address: string;
  city: string;
  state: string;
  postal_code: string;
  phone: string;
  dea_no: string;
  npi_no: string;
  signature: string;
}

interface HeaderContextType {
  LOGO: string;
  ProjectURL: string;
  user: User | null;
}

const HeaderContext = createContext<HeaderContextType | undefined>(undefined);

export const HeaderProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [LOGO, setLOGO] = useState('');
  const [ProjectURL, setProjectURL] = useState('');
  const [user, userDetails] = useState<User | null>(null);

  useEffect(() => {
    const fetchDoctorHeaderData = async () => {
      try {
      const urlParams = new URLSearchParams(window.location.search);

        const doctorID = urlParams.get('doctorID') || urlParams.get('DoctorID') || '';
        const User_Details = await apiService.docViewUser(doctorID);
        userDetails(User_Details.data.DoctorProfile);
        const logo = await apiService.hosLogo(User_Details.data.User.hospital_id);
        setLOGO(logo.data[0]);
        setProjectURL(logo.zoom_url);
        fetchTheme(User_Details.data.User.hospital_id)
      } catch (error) {
        console.error('Error fetching header data:', error);
      }
    };

    const fetchPatientHeaderData = async () => {
        try {
            const urlParams = new URLSearchParams(window.location.search);
          const patientID = urlParams.get('PatientID') ?? '';
          const User_Details = await apiService.patViewUser(patientID);
          userDetails(User_Details.data.PatientProfile);
          const logo = await apiService.hosLogo(User_Details.data.User.hospital_id);
          setLOGO(logo.data[0]);
          setProjectURL(logo.zoom_url);
          fetchTheme(User_Details.data.User.hospital_id)
        } catch (error) {
          console.error('Error fetching header data:', error);
        }
      };

    const urlParams = new URLSearchParams(window.location.search);
    const role = urlParams.get('user_role') ?? '';
    if(role === 'patient'){
        fetchPatientHeaderData()

    }else{
    fetchDoctorHeaderData();

    }
  }, []);

  const contextValue = useMemo(() => ({ LOGO, ProjectURL, user }), [LOGO, ProjectURL, user]);

  const fetchTheme = async (hospital_id: any) => {
    try {
      const data = await apiService.theme(hospital_id);
      const root = document.documentElement;
      root.style.setProperty('--theme-bg', data?.data.video_font_icon);
      document.title = data?.hospita_name
      window.sessionStorage.setItem('hospital',data?.hospita_name)
    } catch (error) {
      console.error('Failed to fetch call ID:', error);
    }
  };

  return (
    <HeaderContext.Provider value={contextValue}>
      {children}
    </HeaderContext.Provider>
  );
};

export const useHeader = () => {
  const context = useContext(HeaderContext);
  if (context === undefined) {
    throw new Error('useHeader must be used within a HeaderProvider');
  }
  return context;
};
