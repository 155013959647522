// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.broadcast-panel {
  display: flex;
  flex-direction: column;
  height: 150px;
  width: 230px;
}
.broadcast-panel .broadcast-panel-content {
  flex-grow: 1;
}
.broadcast-panel .broadcast-panel-content textarea {
  width: 100%;
  height: 100%;
  resize: none;
}
.broadcast-panel .broadcast-panel-btn {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/feature/subsession/component/broadcast-panel.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,aAAA;EACA,YAAA;AACF;AAAE;EACE,YAAA;AAEJ;AADI;EACE,WAAA;EACA,YAAA;EACA,YAAA;AAGN;AAAE;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAEJ","sourcesContent":[".broadcast-panel {\n  display: flex;\n  flex-direction: column;\n  height: 150px;\n  width: 230px;\n  .broadcast-panel-content {\n    flex-grow: 1;\n    textarea {\n      width: 100%;\n      height: 100%;\n      resize: none;\n    }\n  }\n  .broadcast-panel-btn {\n    height: 50px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
