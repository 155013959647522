// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.room-create {
  display: flex;
  flex-direction: column;
}
.room-create .ant-input-number {
  width: 65px;
  margin: 0 3px;
}
.room-create .room-create-title {
  margin-bottom: 30px;
  font-size: 24px;
}
.room-create .room-create-option {
  flex-grow: 1;
}
.room-create .room-create-footer {
  height: 50px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.room-create .room-create-footer .room-create-tip {
  flex-grow: 1;
  font-size: 14px;
  color: #999;
}`, "",{"version":3,"sources":["webpack://./src/feature/subsession/component/subsession-create.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;AAAE;EACE,WAAA;EACA,aAAA;AAEJ;AAAE;EACE,mBAAA;EACA,eAAA;AAEJ;AAAE;EACE,YAAA;AAEJ;AAAE;EACE,YAAA;EACA,aAAA;EACA,2BAAA;EACA,mBAAA;AAEJ;AADI;EACE,YAAA;EACA,eAAA;EACA,WAAA;AAGN","sourcesContent":[".room-create {\n  display: flex;\n  flex-direction: column;\n  .ant-input-number {\n    width: 65px;\n    margin: 0 3px;\n  }\n  .room-create-title {\n    margin-bottom: 30px;\n    font-size: 24px;\n  }\n  .room-create-option {\n    flex-grow: 1;\n  }\n  .room-create-footer {\n    height: 50px;\n    display: flex;\n    flex-direction: row-reverse;\n    align-items: center;\n    .room-create-tip{\n      flex-grow: 1;\n      font-size: 14px;\n      color: #999;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
