// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.share-view {
  display: none;
}
.share-view.share-view-in-sharing {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-right: 1px solid #333;
}
.share-view.share-view-original {
  display: block;
}
.share-view .share-view-viewport {
  max-width: 100%;
}
.share-view .share-view-viewport.share-view-viewport-original {
  max-width: initial;
  cursor: move;
}
.share-view .share-view-viewport.share-view-viewport-in-control {
  cursor: default;
}
.share-view .share-view-viewport .share-view-canvas {
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/feature/doctor/components/share-view.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AAAE;EACE,aAAA;EACA,YAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;EACA,4BAAA;AAEJ;AAAE;EACE,cAAA;AAEJ;AAAE;EACE,eAAA;AAEJ;AADI;EACE,kBAAA;EACA,YAAA;AAGN;AADI;EACE,eAAA;AAGN;AADI;EACE,YAAA;AAGN","sourcesContent":[".share-view {\n  display: none;\n  &.share-view-in-sharing {\n    display: flex;\n    flex-grow: 1;\n    justify-content: center;\n    align-items: center;\n    overflow: hidden;\n    border-right: 1px solid #333;\n  }\n  &.share-view-original {\n    display: block;\n  }\n  .share-view-viewport {\n    max-width: 100%;\n    &.share-view-viewport-original {\n      max-width: initial;\n      cursor: move;\n    }\n    &.share-view-viewport-in-control{\n      cursor: default;\n    }\n    .share-view-canvas {\n      height: 100%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
