export const constructApiUrl = (
  endpoint: string,
  params: Record<string, any> = {},
  filters: Record<string, any> = {}
): string => {
  let url = endpoint;
  const urlParams = new URLSearchParams();
  Object.keys(params).forEach((key) => urlParams.append(key, params[key]));
  if (Object.keys(filters).length > 0) {
    const filterParam = Object.entries(filters)
      .filter(([key, value]) => value !== undefined) // Filter out undefined values
      .map(([key, value]) => `${key}|${value}`)
      .join(',');
    urlParams.append('filters', filterParam);
  }

  if (urlParams.toString()) {
    url += '?' + urlParams.toString();
  }

  return url;
};

export const formatTime = (seconds: number) => {
  const hours = String(Math.floor(seconds / 3600)).padStart(2, '0');
  const minutes = String(Math.floor((seconds % 3600) / 60)).padStart(2, '0');
  const secs = String(seconds % 60).padStart(2, '0');
  return `${hours}:${minutes}:${secs}`;
};
