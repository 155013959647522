import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const Modal = ({ showModal, handleClose, fileUrl }: any) => {
  const isPDF = fileUrl?.endsWith('.pdf');

  return (
    <div
      className={`modal fade ${showModal ? 'show' : ''}`}
      style={{ display: showModal ? 'block' : 'none' }}
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden={!showModal}
    >
      <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">{isPDF ? 'PDF Document' : 'File Attachment'}</h5>
            <button type="button" className="close" onClick={handleClose} aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body" style={{ height: '600px', overflowY: 'auto', textAlign: 'center' }}>
          {isPDF ? (
             /* eslint-disable react/iframe-missing-sandbox */
  <iframe
  src={fileUrl}
  width="100%"
  height="100%"
  style={{ border: 'none' }}
  title="PDF Document"
/>
  ) : (
              <img
                src={fileUrl}
                alt="Chat Attachment"
                crossOrigin="anonymous"
                className="chat_attachment"
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  objectFit: 'contain',
                  width: 'auto',
                  height: '100%',
                }} // Adjust width and height to be auto and full height
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
