import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Swal from 'sweetalert2'; // Import SweetAlert2 for notifications
import apiService from '../services/api';
import './pipClosedModel.scss'

const PipClosedModel = ({ showPipModal, handlePipClose }: any) => {
 
  return (
    <div
      className={`modal fade ${showPipModal ? 'show' : ''}`}
      style={{ display: showPipModal ? 'block' : 'none' }}
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden={!showPipModal}
    >
      <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div className="modal-content">
          {/* <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Invite Doctors</h5>
            <button type="button" className="close" onClick={handlePipClose} aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div> */}
          <div className="modal-body" style={{ overflowY: 'auto', overflowX: 'auto' }}>
          <img src="/icon/min.svg" alt="pip" />
      <h5 className='my-3'>Screen-To-Screen was closed.</h5>
      <button type="button" className="btn btn-primary ok-btn mt-3 join-button"  onClick={handlePipClose} aria-label="Close">ok</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PipClosedModel;
