// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  pointer-events: none;
  z-index: 2;
}
.pagination .previous-page-button, .pagination .next-page-button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 60px !important;
  height: 100px !important;
  pointer-events: auto;
  border: 0;
  background: #000;
}
.pagination .previous-page-button > .anticon, .pagination .next-page-button > .anticon {
  font-size: 56px !important;
}
.pagination.in-sharing {
  top: 0;
  right: 0;
  left: auto;
  width: 264px;
  flex-direction: column;
  transform: none;
  height: 100%;
  align-items: center;
}
.pagination.in-sharing .previous-page-button .anticon, .pagination.in-sharing .next-page-button .anticon {
  transform: rotate(90deg);
}`, "",{"version":3,"sources":["webpack://./src/feature/patient/components/pagination.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,QAAA;EACA,OAAA;EACA,2BAAA;EACA,aAAA;EACA,WAAA;EACA,aAAA;EACA,8BAAA;EACA,oBAAA;EACA,UAAA;AACF;AAAE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;EACA,sBAAA;EACA,wBAAA;EACA,oBAAA;EACA,SAAA;EACA,gBAAA;AAEJ;AADI;EACE,0BAAA;AAGN;AAAE;EACE,MAAA;EACA,QAAA;EACA,UAAA;EACA,YAAA;EACA,sBAAA;EACA,eAAA;EACA,YAAA;EACA,mBAAA;AAEJ;AAAM;EACE,wBAAA;AAER","sourcesContent":[".pagination {\n  position: absolute;\n  top:50%;\n  left: 0;\n  transform: translateY(-50%);\n  height: 100px;\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  pointer-events: none;\n  z-index: 2;\n  .previous-page-button,.next-page-button{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    width: 60px !important;\n    height: 100px !important;\n    pointer-events: auto;\n    border: 0;\n    background: #000;\n    > .anticon{\n      font-size: 56px !important;\n    }\n  }\n  &.in-sharing{\n    top: 0;\n    right:0;\n    left: auto;\n    width: 264px;\n    flex-direction: column;\n    transform: none;\n    height: 100%;\n    align-items: center;\n    .previous-page-button,.next-page-button{\n      .anticon {\n        transform: rotate(90deg);\n      }\n    }\n\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
