import React, { useEffect, useState } from 'react';
import Header from '../../Header';
import './waitingroom.scss';
import { HeaderProvider } from '../../HeaderContext';
import apiService from '../../services/api';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

interface User {
  display_name: string;
  name: string;
  gender: string;
  dob: string;
  ssno: string;
  profile_picture: string;
  email: string;
  address: string;
  city: string;
  state: string;
  postal_code: string;
  phone: string;
  dea_no: string;
  npi_no: string;
  signature: string;
}

const WaitingRoom = () => {
  const history = useHistory();

  const updatePath = () => {
    const currentUrl = new URL(window.location.href);
    const queryParams = currentUrl.search;

    currentUrl.pathname = '/patient';

    return `${currentUrl.pathname}${queryParams}`;
  };

  useEffect(() => {
    let intervalId: any;
  
    const fetchCallID = async () => {
      try {

        const urlParams = new URLSearchParams(window.location.search);
        const topic = urlParams.get('topic') ?? '';
      const DoctorID = urlParams.get('doctorID') || urlParams.get('DoctorID') || '';
      const type = urlParams.get('type') ?? '';
        const data = await apiService.GetUrlInput(topic,DoctorID, type);

        const fetchStatus = async () => {
          try {
            const response = await apiService.GetUrl(data);
            console.log(response, 'calllll', updatePath());
  
            if (response.data.DoctorAvailableStatus.status === 'on_going' || response.data.DoctorAvailableStatus.status === 'busy') {
              setTimeout(() => {
                // history.push(response.data.DoctorAvailableStatus.zoom_url);
                window.location.href = response.data.DoctorAvailableStatus.zoom_url;
              }, 3000);
            }
          } catch (error) {
            console.error('Failed to fetch status:', error);
          }
        };
  
        // Call the second API immediately once
        fetchStatus();
  
        // Then call it every 5 seconds
        intervalId = setInterval(fetchStatus, 5000);
      } catch (error) {
        console.error('Failed to fetch call ID:', error);
      }
    };
  
    fetchCallID();
  
    return () => clearInterval(intervalId);
  }, [history]);

  const [LOGO, setLOGO] = useState('');
  const [ProjectURL, setProjectURL] = useState('');
  const [user, userDetails] = useState<User | null>(null);
  
  useEffect(() => {
    const fetchPatientHeaderData = async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const DoctorID = urlParams.get('doctorID') || urlParams.get('DoctorID') || '';
        const User_Details = await apiService.docViewUser(DoctorID);
        userDetails(User_Details.data.DoctorProfile);
        const logo = await apiService.hosLogo(User_Details.data.User.hospital_id);
        setLOGO(logo.data[0]);
        setProjectURL(logo.zoom_url);
      } catch (error) {
        console.error('Error fetching header data:', error);
      }
    };

    fetchPatientHeaderData();
  }, []);

  

  const LeaveWaitingRoom = async () => {
    const urlParameter = new URLSearchParams(window.location.search);
    const baseURL = urlParameter.get('baseUrl') ?? '';
    window.location.href = baseURL;
  };

  const hospitalName = window.sessionStorage.getItem('hospital');
  
  useEffect(() => {
    const timer = setTimeout(() => {
      if (hospitalName === "Ison Healthcare") {
        Swal.fire({
          position: "top",
          title: "Doctor is currently busy. We will call you back shortly",
          showConfirmButton: false,
          timer: 3500,
          customClass: {
            popup: 'custom-swal-popup',
            title: 'custom-swal-title',
            confirmButton: 'custom-swal-confirm',
          },
        });
      }
    }, 90000); 

    return () => clearTimeout(timer);
  }, [hospitalName]); 

  return (
    <HeaderProvider>
      <div className="main-body">
        <Header />
        {user ? (
          <div className="container my-4 flex-container">
            <h3>Video Consultation</h3>
            <div className="centered-content">
              <div className="custom-container">
                <img
                  src="icon/ar.gif"
                  alt="waiting room"
                  className="custom-image"
                />
          <img 
  crossOrigin="anonymous" 
  src={user.profile_picture} 
  className="rounded-circle" 
  style={{ 
    position: 'absolute', 
    bottom: '80px', 
    width: '150px', 
    height: '150px', 
    borderRadius: '50%',
  }} 
/>

              </div>
            </div>
            <h6>Waiting for</h6>
            <h3>{user.name ? user.name : user?.display_name ?? user?.display_name}</h3>
            <h6>To Join the call</h6>
     
              <button  onClick={() => LeaveWaitingRoom()} className='btn btn-primary mt-2 join-button'>
                Exit from Waiting room
              </button>
 
          </div>
        ) : (
          <p>...</p>
        )}
      </div>
    </HeaderProvider>
  );
}

export default WaitingRoom;
