'use client';
import useFcmToken from '../hooks/useFCMToken';
import { getMessaging, onMessage } from 'firebase/messaging';
import firebaseApp from '../firebase/firebase';
import { useEffect } from 'react';

export default function FcmTokenComp() {
  const { token, notificationPermissionStatus } = useFcmToken();
  console.log(token, 'fcmToken');
  useEffect(() => {
    if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
      if (notificationPermissionStatus === 'granted') {
        const messaging = getMessaging(firebaseApp);
        console.log(messaging, 'messaging');
        const unsubscribe = onMessage(messaging, payload => {
          console.log('Foreground push notification received:', payload);
        });

        return () => {
          unsubscribe(); // Unsubscribe from the onMessage event on cleanup
        };
      }
    }
  }, [notificationPermissionStatus, token]);

  const handleForegroundNotification = payload => {
    // Example: Displaying a notification
    const notificationTitle = payload.data.title;
    const notificationOptions = {
      body: payload.data.body,
      icon: payload.data.icon,
      badge: payload.data.badge,
    };

    if (Notification.permission === 'granted') {
      // eslint-disable-next-line no-new
      new Notification(notificationTitle, notificationOptions);
    }
  };

  return null; // This component is primarily for handling foreground notifications
}
