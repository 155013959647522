// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.breakout-room-viewport {
  position: relative;
}
.breakout-room-viewport .breakout-room-btn,
.breakout-room-viewport .breakout-room-attendee-dropdown {
  position: absolute;
  right: 2vw;
  bottom: 10vh;
}
.breakout-room-viewport .room-remaining {
  position: absolute;
  right: 2vw;
  top: 2vh;
  padding: 5px 10px;
  border-radius: 4px;
  background: rgba(111, 111, 111, 0.3);
  color: #fff;
}
.breakout-room-viewport .room-info {
  position: absolute;
  top: 2vh;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  font-size: 16px;
  font-weight: 700;
}

.breakout-room-title {
  width: 100%;
  cursor: move;
}

.attendee-bo-menu {
  border: 1px solid #747487;
  background: rgba(0, 0, 0, 0.9) !important;
}
.attendee-bo-menu .ant-dropdown-menu-item {
  padding: 10px 30px;
  color: #ccc;
  position: relative;
}
.attendee-bo-menu .ant-dropdown-menu-item:hover {
  color: #40a9ff;
}`, "",{"version":3,"sources":["webpack://./src/feature/subsession/subsession.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AAAE;;EAEE,kBAAA;EACA,UAAA;EACA,YAAA;AAEJ;AAAE;EACE,kBAAA;EACA,UAAA;EACA,QAAA;EACA,iBAAA;EACA,kBAAA;EACA,oCAAA;EACA,WAAA;AAEJ;AAAE;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,2BAAA;EACA,WAAA;EACA,eAAA;EACA,gBAAA;AAEJ;;AACA;EACE,WAAA;EACA,YAAA;AAEF;;AAAA;EACE,yBAAA;EACA,yCAAA;AAGF;AAFE;EACE,kBAAA;EACA,WAAA;EACA,kBAAA;AAIJ;AAHI;EACE,cAAA;AAKN","sourcesContent":[".breakout-room-viewport {\n  position: relative;\n  .breakout-room-btn,\n  .breakout-room-attendee-dropdown {\n    position: absolute;\n    right: 2vw;\n    bottom: 10vh;\n  }\n  .room-remaining {\n    position: absolute;\n    right: 2vw;\n    top: 2vh;\n    padding: 5px 10px;\n    border-radius: 4px;\n    background: rgba(111, 111, 111, 0.3);\n    color: #fff;\n  }\n  .room-info {\n    position: absolute;\n    top: 2vh;\n    left: 50%;\n    transform: translateX(-50%);\n    color: #fff;\n    font-size: 16px;\n    font-weight: 700;\n  }\n}\n.breakout-room-title {\n  width: 100%;\n  cursor: move;\n}\n.attendee-bo-menu {\n  border: 1px solid #747487;\n  background: rgba(0, 0, 0, 0.9) !important;\n  .ant-dropdown-menu-item {\n    padding: 10px 30px;\n    color: #ccc;\n    position: relative;\n    &:hover {\n      color: #40a9ff;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
