/* eslint-disable prettier/prettier */
import { useState, useCallback, useContext, useEffect, MutableRefObject } from 'react';
import classNames from 'classnames';
import { message } from 'antd';
import ZoomContext from '../../../context/zoom-context';
import CameraButton from './camera';
import MicrophoneButton from './microphone';
import { ScreenShareButton } from './screen-share';
import AudioVideoStatisticModal from './audio-video-statistic';
import ZoomMediaContext from '../../../context/media-context';
import { useUnmount, useMount } from '../../../hooks';
import { MediaDevice } from '../video-types';
import './video-footer.scss';
import { isAndroidOrIOSBrowser, isIOSMobile } from '../../../utils/platform';
import { getPhoneCallStatusDescription, SELF_VIDEO_ID } from '../video-constants';
import { getRecordingButtons, RecordButtonProps, RecordingButton } from './recording';
import {
  DialoutState,
  RecordingStatus,
  MutedSource,
  AudioChangeAction,
  DialOutOption,
  VideoCapturingState,
  SharePrivilege,
  MobileVideoFacingMode,
  LiveStreamStatus,
  ShareStatus
} from '@zoom/videosdk';
import { LiveTranscriptionButton } from './live-transcription';
import { LeaveButton } from './leave';
import { TranscriptionSubtitle } from './transcription-subtitle';
import IsoRecordingModal from './recording-ask-modal';
import { LiveStreamButton, LiveStreamModal } from './live-stream';
import { IconFont } from '../../../component/icon-font';
import { VideoMaskModel } from './video-mask-modal';
import Swal from 'sweetalert2';
import apiService from '../../../services/api';
import { formatTime } from '../../../services/common';
import LoadingLayer from '../../../component/loading-layer';
import { InviteButton } from './invite';
import InviteModel from '../../../component/InviteModel';

interface VideoFooterProps {
  className?: string;
  selfShareCanvas?: HTMLCanvasElement | HTMLVideoElement | null;
  sharing?: boolean;
  data?: any;
}

const isAudioEnable = typeof AudioWorklet === 'function';
const VideoFooter = (props: VideoFooterProps) => {
  const { className, selfShareCanvas, sharing, data } = props;
  const zmClient = useContext(ZoomContext);
  const { mediaStream } = useContext(ZoomMediaContext);
  const liveTranscriptionClient = zmClient.getLiveTranscriptionClient();
  const liveStreamClient = zmClient.getLiveStreamClient();
  const recordingClient = zmClient.getRecordingClient();
  const [isStartedAudio, setIsStartedAudio] = useState(
    zmClient.getCurrentUserInfo() && zmClient.getCurrentUserInfo().audio !== ''
  );
  const [isStartedVideo, setIsStartedVideo] = useState(zmClient.getCurrentUserInfo()?.bVideoOn);
  const [audio, setAudio] = useState(zmClient.getCurrentUserInfo()?.audio);
  const [isSupportPhone, setIsSupportPhone] = useState(false);
  const [phoneCountryList, setPhoneCountryList] = useState<any[]>([]);
  const [phoneCallStatus, setPhoneCallStatus] = useState<DialoutState>();
  const [isStartedLiveTranscription, setIsStartedLiveTranscription] = useState(false);
  const [isDisableCaptions, setIsDisableCaptions] = useState(false);
  const [isMirrored, setIsMirrored] = useState(false);
  const [isBlur, setIsBlur] = useState(false);
  const [isMuted, setIsMuted] = useState(!!zmClient.getCurrentUserInfo()?.muted);
  const [activeMicrophone, setActiveMicrophone] = useState(mediaStream?.getActiveMicrophone());
  const [activeSpeaker, setActiveSpeaker] = useState(mediaStream?.getActiveSpeaker());
  const [activeCamera, setActiveCamera] = useState(mediaStream?.getActiveCamera());
  const [micList, setMicList] = useState<MediaDevice[]>(mediaStream?.getMicList() ?? []);
  const [speakerList, setSpeakerList] = useState<MediaDevice[]>(mediaStream?.getSpeakerList() ?? []);
  const [cameraList, setCameraList] = useState<MediaDevice[]>(mediaStream?.getCameraList() ?? []);
  const [statisticVisible, setStatisticVisible] = useState(false);
  const [selecetedStatisticTab, setSelectedStatisticTab] = useState('audio');
  const [isComputerAudioDisabled, setIsComputerAudioDisabled] = useState(false);
  const [sharePrivilege, setSharePrivileg] = useState(SharePrivilege.Unlocked);
  const [caption, setCaption] = useState({ text: '', isOver: false });
  const [activePlaybackUrl, setActivePlaybackUrl] = useState('');
  const [isMicrophoneForbidden, setIsMicrophoneForbidden] = useState(false);
  const [recordingStatus, setRecordingStatus] = useState<'' | RecordingStatus>(
    recordingClient?.getCloudRecordingStatus() || ''
  );
  const [recordingIsoStatus, setRecordingIsoStatus] = useState<'' | RecordingStatus>('');
  const [liveStreamVisible, setLiveStreamVisible] = useState(false);
  const [liveStreamStatus, setLiveStreamStatus] = useState(liveStreamClient?.getLiveStreamStatus());
  // Video Mask
  const [videoMaskVisible, setVideoMaskVisible] = useState(false);

  // const onCameraClick = useCallback(async () => {
  //   if (isStartedVideo) {
  //     await mediaStream?.stopVideo();
  //     setIsStartedVideo(false);
  //   } else {
  //     const temporaryException = isIOSMobile() && window.crossOriginIsolated; // add ios mobile exception for test backward compatible.
  //     if (mediaStream?.isRenderSelfViewWithVideoElement() && !temporaryException) {
  //       const videoElement = document.querySelector(`#${SELF_VIDEO_ID}`) as HTMLVideoElement;
  //       if (videoElement) {
  //         await mediaStream?.startVideo({ videoElement });
  //       }
  //     } else {
  //       const startVideoOptions = { hd: true, ptz: mediaStream?.isBrowserSupportPTZ() };
  //       if (mediaStream?.isSupportVirtualBackground() && isBlur) {
  //         Object.assign(startVideoOptions, { virtualBackground: { imageUrl: 'blur' } });
  //       }
  //       await mediaStream?.startVideo(startVideoOptions);
  //       if (!mediaStream?.isSupportMultipleVideos()) {
  //         const canvasElement = document.querySelector(`#${SELF_VIDEO_ID}`) as HTMLCanvasElement;
  //         mediaStream?.renderVideo(
  //           canvasElement,
  //           zmClient.getSessionInfo().userId,
  //           canvasElement.width,
  //           canvasElement.height,
  //           0,
  //           0,
  //           3
  //         );
  //       }
  //     }

  //     setIsStartedVideo(true);
  //   }
  // }, [mediaStream, isStartedVideo, zmClient, isBlur]);

  const onCameraClick = useCallback(async () => {
    try {
      if (isStartedVideo) {
        await mediaStream?.stopVideo();
        setIsStartedVideo(false);
      } else {
        const temporaryException = isIOSMobile() && window.crossOriginIsolated; // add ios mobile exception for test backward compatible.
        if (mediaStream?.isRenderSelfViewWithVideoElement() && !temporaryException) {
          const videoElement = document.querySelector(`#${SELF_VIDEO_ID}`) as HTMLVideoElement;
          if (videoElement) {
            await mediaStream?.startVideo({ videoElement });
          }
        } else {
          const startVideoOptions = { hd: true, ptz: mediaStream?.isBrowserSupportPTZ() };
          if (mediaStream?.isSupportVirtualBackground() && isBlur) {
            Object.assign(startVideoOptions, { virtualBackground: { imageUrl: backgroundImage } });
          }
          await mediaStream?.startVideo(startVideoOptions);
          if (!mediaStream?.isSupportMultipleVideos()) {
            const canvasElement = document.querySelector(`#${SELF_VIDEO_ID}`) as HTMLCanvasElement;
            mediaStream?.renderVideo(
              canvasElement,
              zmClient.getSessionInfo().userId,
              canvasElement.width,
              canvasElement.height,
              0,
              0,
              3
            );
          }
        }
        setIsStartedVideo(true);
      }
    } catch (error: any) {
      console.error('An error occurred while handling camera click:', error);
      
      if (error?.type === 'CAN_NOT_DETECT_CAMERA' || error?.type === 'INVALID_OPERATION') {
        setTimeout(async () => {
          try {
            // Attempt to stop and restart the camera
            await mediaStream?.stopVideo();
            setIsStartedVideo(false);
    
            // Restart the camera after stopping it
            const startVideoOptions = { hd: true, ptz: mediaStream?.isBrowserSupportPTZ() };
            if (mediaStream?.isSupportVirtualBackground() && isBlur) {
              Object.assign(startVideoOptions, { virtualBackground: { imageUrl: backgroundImage } });
            }
            await mediaStream?.startVideo(startVideoOptions);
            setIsStartedVideo(true);
          } catch (restartError) {
            console.error('Failed to restart the video after detection failure:', restartError);
            // alert('Please enable your camera or check your camera permissions.');
          }
        }, 2000);
       
      } else {
        // alert('Please enable your camera permission');
      }
       
      // alert('Please Enable your camera permission');
      // Handle the error accordingly, such as displaying an error message to the user
    }
    await mediaStream?.mirrorVideo(true);
    setIsMirrored(true);
  }, [mediaStream, isStartedVideo, zmClient, isBlur]);

  useEffect(()=>{
    setTimeout(async () => {
      try {
        // Attempt to stop and restart the camera
        console.log('Attempt to stop and restart the camera');
        
        await mediaStream?.stopVideo();
        setIsStartedVideo(false);

        // Restart the camera after stopping it
        const startVideoOptions = { hd: true, ptz: mediaStream?.isBrowserSupportPTZ() };
        if (mediaStream?.isSupportVirtualBackground() && isBlur) {
          Object.assign(startVideoOptions, { virtualBackground: { imageUrl: backgroundImage } });
        }
        await mediaStream?.startVideo(startVideoOptions);
        setIsStartedVideo(true);
      } catch (restartError) {
        console.error('Failed to restart the video after detection failure:', restartError);
        // alert('Please enable your camera or check your camera permissions.');
      }
    }, 2000);

},[window.sessionStorage.getItem('visibleParticipants')])

  // const onCameraClick = useCallback(async () => {
  //   try {
  //     if (isStartedVideo) {
  //       await mediaStream?.stopVideo();
  //       setIsStartedVideo(false);
  //     } else {
  //       const temporaryException = isIOSMobile() && window.crossOriginIsolated; // iOS mobile exception for backward compatibility
  //       if (mediaStream?.isRenderSelfViewWithVideoElement() && !temporaryException) {
  //         const videoElement = document.querySelector(`#${SELF_VIDEO_ID}`) as HTMLVideoElement;
  //         if (videoElement) {
  //           await mediaStream?.startVideo({ videoElement });
  //         }
  //       } else {
  //         const startVideoOptions = { hd: true, ptz: mediaStream?.isBrowserSupportPTZ() };
  //         if (mediaStream?.isSupportVirtualBackground() && isBlur) {
  //           Object.assign(startVideoOptions, { virtualBackground: { imageUrl: '/images/image.png' } });
  //         }
  //         await mediaStream?.startVideo(startVideoOptions);
  //         if (!mediaStream?.isSupportMultipleVideos()) {
  //           const canvasElement = document.querySelector(`#${SELF_VIDEO_ID}`) as HTMLCanvasElement;
  //           mediaStream?.renderVideo(
  //             canvasElement,
  //             zmClient.getSessionInfo().userId,
  //             canvasElement.width,
  //             canvasElement.height,
  //             0,
  //             0,
  //             3
  //           );
  //         }
  //       }
  //       setIsStartedVideo(true);
  //     }
  //   } catch (error: any) {
  //     console.error('An error occurred while handling camera click:', error);
  
  //     if (error?.type === 'CAN_NOT_DETECT_CAMERA' || error?.type === 'INVALID_OPERATION') {
  //       setTimeout(async () => {
  //         try {
  //           // Attempt to stop and restart the camera
  //           await mediaStream?.stopVideo();
  //           setIsStartedVideo(false);
    
  //           // Restart the camera after stopping it
  //           const startVideoOptions = { hd: true, ptz: mediaStream?.isBrowserSupportPTZ() };
  //           if (mediaStream?.isSupportVirtualBackground() && isBlur) {
  //             Object.assign(startVideoOptions, { virtualBackground: { imageUrl: '/images/image.png' } });
  //           }
  //           await mediaStream?.startVideo(startVideoOptions);
  //           setIsStartedVideo(true);
  //         } catch (restartError) {
  //           console.error('Failed to restart the video after detection failure:', restartError);
  //           // alert('Please enable your camera or check your camera permissions.');
  //         }
  //       }, 2000);
       
  //     } else {
  //       // alert('Please enable your camera permission');
  //     }
  //   }
  // }, [mediaStream, isStartedVideo, zmClient, isBlur]);
  

  const onMicrophoneClick = useCallback(async () => {
    try {
      if (isStartedAudio) {
        if (isMuted) {
          await mediaStream?.unmuteAudio();
        } else {
          await mediaStream?.muteAudio();
        }
      } else {
        try {
          await mediaStream?.startAudio();
        } catch (e: any) {
          if (e.type === 'INSUFFICIENT_PRIVILEGES' && e.reason === 'USER_FORBIDDEN_MICROPHONE') {
            setIsMicrophoneForbidden(true);
          }
           
          // alert('Please Enable your microphone permission');
          // console.warn(e);
        }
        setIsStartedAudio(true);
      }
    } catch (error) {
      console.error('An error occurred while handling microphone click:', error);
      setIsStartedAudio(false);
      setIsMuted(true);
       
      // alert('Please Enable your microphone permission');

      // Handle the error accordingly, such as displaying an error message to the user
    }
  }, [mediaStream, isStartedAudio, isMuted]);
  const onMicrophoneMenuClick = async (key: string) => {
    if (mediaStream) {
      const [type, deviceId] = key.split('|');
      if (type === 'microphone') {
        if (deviceId !== activeMicrophone) {
          await mediaStream.switchMicrophone(deviceId);
          setActiveMicrophone(mediaStream.getActiveMicrophone());
        }
      } else if (type === 'speaker') {
        if (deviceId !== activeSpeaker) {
          await mediaStream.switchSpeaker(deviceId);
          setActiveSpeaker(mediaStream.getActiveSpeaker());
        }
      } else if (type === 'leave audio') {
        if (audio === 'computer') {
          await mediaStream.stopAudio();
        } else if (audio === 'phone') {
          await mediaStream.hangup();
          setPhoneCallStatus(undefined);
        }
        setIsStartedAudio(false);
      } else if (type === 'statistic') {
        setSelectedStatisticTab('audio');
        setStatisticVisible(true);
      }
    }
  };
  const onSwitchCamera = async (key: string) => {
    if (mediaStream) {
      if (activeCamera !== key) {
        await mediaStream.switchCamera(key);
        setActiveCamera(mediaStream.getActiveCamera());
        setActivePlaybackUrl('');
      }
    }
  };
  const onMirrorVideo = async () => {
    await mediaStream?.mirrorVideo(!isMirrored);
    setIsMirrored(!isMirrored);
  };

  function getHospitalBackgroundImage(): string {
    const hospitalImages: { [key: string]: string } = {
      'Ison Healthcare': '/images/ISON.png',
      'American Hospital Dubai': '/images/AHD.png',
    };
  
    const hospitalName = window.sessionStorage.getItem('hospital_name') || '';
    return hospitalImages[hospitalName] || '/images/image.png'; 
  }
  
  const backgroundImage = getHospitalBackgroundImage();
  

  const onBlurBackground = async () => {
    const isSupportVirtualBackground = mediaStream?.isSupportVirtualBackground();
    if (isSupportVirtualBackground) {
      if (isBlur) {
        await mediaStream?.updateVirtualBackgroundImage(undefined);
      } else {
        await mediaStream?.updateVirtualBackgroundImage(backgroundImage);
      }
    } else {
      setVideoMaskVisible(true);
    }

    setIsBlur(!isBlur);
  };
  const onPhoneCall = async (code: string, phoneNumber: string, name: string, option: DialOutOption) => {
    await mediaStream?.inviteByPhone(code, phoneNumber, name, option);
  };
  const onPhoneCallCancel = async (code: string, phoneNumber: string, option: { callMe: boolean }) => {
    if ([DialoutState.Calling, DialoutState.Ringing, DialoutState.Accepted].includes(phoneCallStatus as any)) {
      await mediaStream?.cancelInviteByPhone(code, phoneNumber, option);
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve(true);
        }, 3000);
      });
    }
    return Promise.resolve();
  };
  const onHostAudioMuted = useCallback((payload) => {
    const { action, source, type } = payload;
    if (action === AudioChangeAction.Join) {
      setIsStartedAudio(true);
      setAudio(type);
    } else if (action === AudioChangeAction.Leave) {
      setIsStartedAudio(false);
    } else if (action === AudioChangeAction.Muted) {
      setIsMuted(true);
      if (source === MutedSource.PassiveByMuteOne) {
        message.info('Host muted you');
      }
    } else if (action === AudioChangeAction.Unmuted) {
      setIsMuted(false);
      if (source === 'passive') {
        message.info('Host unmuted you');
      }
    }
  }, []);
  const onScreenShareClick = useCallback(async () => {
    if (mediaStream?.getShareStatus() === ShareStatus.End && selfShareCanvas) {
      await mediaStream?.startShareScreen(selfShareCanvas, { requestReadReceipt: true });
    }
  }, [mediaStream, selfShareCanvas]);

  const onLiveTranscriptionClick = useCallback(async () => {
    if (isDisableCaptions) {
      message.info('Captions has been disable by host.');
    } else if (isStartedLiveTranscription) {
      message.info('Live transcription has started.');
    } else if (!isStartedLiveTranscription) {
      await liveTranscriptionClient?.startLiveTranscription();
      setIsStartedLiveTranscription(true);
    }
  }, [isStartedLiveTranscription, isDisableCaptions, liveTranscriptionClient]);

  const onDisableCaptions = useCallback(
    async (disable: boolean) => {
      if (disable && !isDisableCaptions) {
        await liveTranscriptionClient?.disableCaptions(disable);
        setIsStartedLiveTranscription(false);
        setIsDisableCaptions(true);
      } else if (!disable && isDisableCaptions) {
        await liveTranscriptionClient?.disableCaptions(disable);
        setIsDisableCaptions(false);
      }
    },
    [isDisableCaptions, liveTranscriptionClient]
  );
  const urlParams = new URLSearchParams(window.location.search);
  const meetingID = urlParams.get('meetingID');

  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('Leaving the room...');

  const onLeaveClick = useCallback(async () => {
    if (meetingID === '') {
      Swal.fire({
        title: 'Do you want to end the session?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'var(--theme-bg)',
        cancelButtonColor: '#A0A2B3',
        confirmButtonText: `Yes, sure!`,
        allowOutsideClick: false
      }).then(async (result) => {
        if (result.isConfirmed) {
          setIsLoading(true);
          await apiService.ZoomRecording();
          await zmClient.leave();
          const urlParameter = new URLSearchParams(window.location.search);
          const baseURL = urlParameter.get('baseUrl') || '';
          const doctorID = urlParameter.get('doctorID') || urlParams.get('DoctorID') || '';
          
          const url = new URL(baseURL);
          
          url.pathname = `${url.pathname.replace(/\/$/, '')}/doctor/dashboard`;
          
          if (doctorID) {
            url.searchParams.append('id', doctorID);
          }
          
          window.location.href = url.toString();
        }
      });
    } else {
      Swal.fire({
        title: 'Do you want to end the session?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'var(--theme-bg)',
        cancelButtonColor: '#A0A2B3',
        confirmButtonText: `Yes, sure!`,
        allowOutsideClick: false
      }).then(async (result) => {
        if (result.isConfirmed) {
          await zmClient.leave();
          data();
        }
      });
    }
  }, [zmClient]);

  const onEndClick = useCallback(async () => {
    if (meetingID === '') {
      Swal.fire({
        title: 'Do you want to end the session?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'var(--theme-bg)',
        cancelButtonColor: '#A0A2B3',
        confirmButtonText: `Yes, sure!`,
        allowOutsideClick: false
      }).then(async (result) => {
        if (result.isConfirmed) {
          setIsLoading(true);
          await apiService.ZoomRecording();
          await zmClient.leave();
          const urlParameter = new URLSearchParams(window.location.search);
        const baseURL = urlParameter.get('baseUrl') || '';
        const doctorID = urlParameter.get('doctorID') || urlParams.get('DoctorID') || '';
        
        const url = new URL(baseURL);
        
        url.pathname = `${url.pathname.replace(/\/$/, '')}/doctor/dashboard`;
        
        if (doctorID) {
          url.searchParams.append('id', doctorID);
        }
        
        window.location.href = url.toString();
        }
      });
    } else {
      Swal.fire({
        title: 'Do you want to end the session?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'var(--theme-bg)',
        cancelButtonColor: '#A0A2B3',
        confirmButtonText: `Yes, sure!`,
        allowOutsideClick: false
      }).then(async (result) => {
        if (result.isConfirmed) {
          await zmClient.leave(true);
          data();
        }
      });
    }
  }, [zmClient]);

  const onPassivelyStopShare = useCallback(({ reason }) => {
    console.log('passively stop reason:', reason);
  }, []);
  const onDeviceChange = useCallback(() => {
    if (mediaStream) {
      setMicList(mediaStream.getMicList());
      setSpeakerList(mediaStream.getSpeakerList());
      if (!isAndroidOrIOSBrowser()) {
        setCameraList(mediaStream.getCameraList());
      }
      setActiveMicrophone(mediaStream.getActiveMicrophone());
      setActiveSpeaker(mediaStream.getActiveSpeaker());
      setActiveCamera(mediaStream.getActiveCamera());
    }
  }, [mediaStream]);

  const onRecordingChange = useCallback(() => {
    setRecordingStatus(recordingClient?.getCloudRecordingStatus() || '');
  }, [recordingClient]);

  const onRecordingISOChange = useCallback(
    (payload: any) => {
      if (payload?.userId === zmClient.getSessionInfo().userId || payload?.status === RecordingStatus.Ask) {
        setRecordingIsoStatus(payload?.status);
      }
      console.log('recording-iso-change', payload);
    },
    [zmClient]
  );

  const onDialOutChange = useCallback((payload) => {
    setPhoneCallStatus(payload.code);
  }, []);
  

  const onRecordingClick = async (key: string) => {
    switch (key) {
      case 'Record': {
        await recordingClient?.startCloudRecording();
        break;
      }
      case 'Resume': {
        await recordingClient?.resumeCloudRecording();
        break;
      }
      case 'Stop': {
        await recordingClient?.stopCloudRecording();
        break;
      }
      case 'Pause': {
        await recordingClient?.pauseCloudRecording();
        break;
      }
      case 'Status': {
        break;
      }
      default: {
        await recordingClient?.startCloudRecording();
      }
    }
  };
  const onVideoCaptureChange = useCallback((payload) => {
    if (payload.state === VideoCapturingState.Started) {
      setIsStartedVideo(true);
    } else {
      setIsStartedVideo(false);
    }
  }, []);
  const onShareAudioChange = useCallback(
    (payload) => {
      const { state } = payload;
      if (state === 'on') {
        if (!mediaStream?.isSupportMicrophoneAndShareAudioSimultaneously()) {
          setIsComputerAudioDisabled(true);
        }
      } else if (state === 'off') {
        setIsComputerAudioDisabled(false);
      }
    },
    [mediaStream]
  );
  const onHostAskToUnmute = useCallback((payload) => {
    const { reason } = payload;
    console.log(`Host ask to unmute the audio.`, reason);
  }, []);

  const onCaptionStatusChange = useCallback((payload) => {
    const { autoCaption } = payload;
    if (autoCaption) {
      message.info('Auto live transcription enabled!');
    }
  }, []);

  const onCaptionMessage = useCallback((payload) => {
    const { text, done } = payload;
    setCaption({
      text,
      isOver: done
    });
  }, []);

  const onCaptionDisable = useCallback((payload) => {
    setIsDisableCaptions(payload);
    if (payload) {
      setIsStartedLiveTranscription(false);
    }
  }, []);

  const onCanSeeMyScreen = useCallback(() => {
    message.info('Users can now see your screen', 1);
  }, []);
  const onSelectVideoPlayback = useCallback(
    async (url: string) => {
      if (activePlaybackUrl !== url) {
        await mediaStream?.switchCamera({ url, loop: true });
        if (isStartedAudio) {
          await mediaStream?.switchMicrophone({ url, loop: true });
        } else {
          await mediaStream?.startAudio({ mediaFile: { url, loop: true } });
        }
        setActivePlaybackUrl(url);
      }
    },
    [isStartedAudio, activePlaybackUrl, mediaStream]
  );

  const onLiveStreamClick = useCallback(() => {
    if (liveStreamStatus === LiveStreamStatus.Ended) {
      setLiveStreamVisible(true);
    } else if (liveStreamStatus === LiveStreamStatus.InProgress) {
      liveStreamClient?.stopLiveStream();
    }
  }, [liveStreamStatus, liveStreamClient]);
  const onLiveStreamStatusChange = useCallback((status) => {
    setLiveStreamStatus(status);
    if (status === LiveStreamStatus.Timeout) {
      // message.error('Start live streaming timeout');
    }
  }, []);
  useEffect(() => {
    zmClient.on('current-audio-change', onHostAudioMuted);
    zmClient.on('passively-stop-share', onPassivelyStopShare);
    zmClient.on('device-change', onDeviceChange);
    zmClient.on('recording-change', onRecordingChange);
    zmClient.on('individual-recording-change', onRecordingISOChange);
    zmClient.on('dialout-state-change', onDialOutChange);
    zmClient.on('video-capturing-change', onVideoCaptureChange);
    zmClient.on('share-audio-change', onShareAudioChange);
    zmClient.on('host-ask-unmute-audio', onHostAskToUnmute);
    zmClient.on('caption-status', onCaptionStatusChange);
    zmClient.on('caption-message', onCaptionMessage);
    zmClient.on('caption-host-disable', onCaptionDisable);
    zmClient.on('share-can-see-screen', onCanSeeMyScreen);
    zmClient.on('live-stream-status', onLiveStreamStatusChange);
    return () => {
      zmClient.off('current-audio-change', onHostAudioMuted);
      zmClient.off('passively-stop-share', onPassivelyStopShare);
      zmClient.off('device-change', onDeviceChange);
      zmClient.off('recording-change', onRecordingChange);
      zmClient.off('individual-recording-change', onRecordingISOChange);
      zmClient.off('dialout-state-change', onDialOutChange);
      zmClient.off('video-capturing-change', onVideoCaptureChange);
      zmClient.off('share-audio-change', onShareAudioChange);
      zmClient.off('host-ask-unmute-audio', onHostAskToUnmute);
      zmClient.off('caption-status', onCaptionStatusChange);
      zmClient.off('caption-message', onCaptionMessage);
      zmClient.off('caption-host-disable', onCaptionDisable);
      zmClient.off('share-can-see-screen', onCanSeeMyScreen);
      zmClient.off('live-stream-status', onLiveStreamStatusChange);
    };
  }, [
    zmClient,
    onHostAudioMuted,
    onPassivelyStopShare,
    onDeviceChange,
    onRecordingChange,
    onDialOutChange,
    onVideoCaptureChange,
    onShareAudioChange,
    onHostAskToUnmute,
    onCaptionStatusChange,
    onCaptionMessage,
    onCanSeeMyScreen,
    onRecordingISOChange,
    onCaptionDisable,
    onLiveStreamStatusChange
  ]);
  useUnmount(() => {
    if (isStartedAudio) {
      mediaStream?.stopAudio();
    }
    if (isStartedVideo) {
      mediaStream?.stopVideo();
    }
    mediaStream?.stopShareScreen();
  });
  useMount(() => {
    if (mediaStream) {
      setIsSupportPhone(!!mediaStream.isSupportPhoneFeature());
      setPhoneCountryList(mediaStream.getSupportCountryInfo() || []);
      setSharePrivileg(mediaStream.getSharePrivilege());
      if (isAndroidOrIOSBrowser()) {
        setCameraList([
          { deviceId: MobileVideoFacingMode.User, label: 'Front-facing' },
          { deviceId: MobileVideoFacingMode.Environment, label: 'Rear-facing' }
        ]);
      }
    }
  });
  useEffect(() => {
    if (mediaStream && zmClient.getSessionInfo().isInMeeting) {
      mediaStream.subscribeAudioStatisticData();
      mediaStream.subscribeVideoStatisticData();
      mediaStream.subscribeShareStatisticData();
    }
    return () => {
      if (zmClient.getSessionInfo().isInMeeting) {
        mediaStream?.unsubscribeAudioStatisticData();
        mediaStream?.unsubscribeVideoStatisticData();
        mediaStream?.unsubscribeShareStatisticData();
      }
    };
  }, [mediaStream, zmClient]);

  const [callDuration, setCallDuration] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCallDuration(prevDuration => prevDuration + 1);
    }, 1000);

    return () => clearInterval(timer);
  }, []);
  const recordingButtons: RecordButtonProps[] = getRecordingButtons(recordingStatus, zmClient.isHost());

  const [showModal, setShowModal] = useState(false);
  const handleOpen = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const hospitalName = window.sessionStorage.getItem('hospital_name');
  return (
    <div className={classNames('video-footer', className)}>

    <div className="fs-6 fw-medium col-auto" style={{ marginRight: "min(30px, 1vw)" }}>
        {formatTime(callDuration)}
      </div>

      {isAudioEnable && (
       <MicrophoneButton
       isStartedAudio={isStartedAudio}
       isMuted={isMuted}
       isSupportPhone={isSupportPhone}
       audio={audio}
       phoneCountryList={phoneCountryList}
       onPhoneCallClick={onPhoneCall}
       onPhoneCallCancel={onPhoneCallCancel}
       phoneCallStatus={getPhoneCallStatusDescription(phoneCallStatus)}
       onMicrophoneClick={onMicrophoneClick}
       onMicrophoneMenuClick={onMicrophoneMenuClick}
       microphoneList={micList}
       speakerList={speakerList}
       activeMicrophone={activeMicrophone}
       activeSpeaker={activeSpeaker}
       disabled={isComputerAudioDisabled}
       isMicrophoneForbidden={isMicrophoneForbidden}
     />
      )}
    <CameraButton
        isStartedVideo={isStartedVideo}
        onCameraClick={onCameraClick}
        onSwitchCamera={onSwitchCamera}
        onMirrorVideo={onMirrorVideo}
        onVideoStatistic={() => {
          setSelectedStatisticTab('video');
          setStatisticVisible(true);
        }}
        onBlurBackground={onBlurBackground}
        onSelectVideoPlayback={onSelectVideoPlayback}
        activePlaybackUrl={activePlaybackUrl}
        cameraList={cameraList}
        activeCamera={activeCamera}
        isMirrored={isMirrored}
        isBlur={isBlur}
      />
      {/* {sharing && (
        <ScreenShareButton
          sharePrivilege={sharePrivilege}
          isHostOrManager={zmClient.isHost() || zmClient.isManager()}
          onScreenShareClick={onScreenShareClick}
          onSharePrivilegeClick={async (privilege) => {
            await mediaStream?.setSharePrivilege(privilege);
            setSharePrivileg(privilege);
          }}
        />
      )} */}

      {/* {recordingButtons.map((button: RecordButtonProps) => {
        return (
          <RecordingButton
            key={button.text}
            onClick={() => {
              onRecordingClick(button.text);
            }}
            {...button}
          />
        );
      })}
      {/* {liveTranscriptionClient?.getLiveTranscriptionStatus().isLiveTranscriptionEnabled && (
        <>
          <LiveTranscriptionButton
            isStartedLiveTranscription={isStartedLiveTranscription}
            isDisableCaptions={isDisableCaptions}
            isHost={zmClient.isHost()}
            onDisableCaptions={onDisableCaptions}
            onLiveTranscriptionClick={onLiveTranscriptionClick}
          />
          <TranscriptionSubtitle text={caption.text} />
        </>
      )} */}
      {/* {liveStreamClient?.isLiveStreamEnabled() && zmClient.isHost() && (
        <>
          <LiveStreamButton
            isLiveStreamOn={liveStreamStatus === LiveStreamStatus.InProgress}
            onLiveStreamClick={onLiveStreamClick}
          />
          <LiveStreamModal
            visible={liveStreamVisible}
            setVisible={setLiveStreamVisible}
            onStartLiveStream={(streanUrl: string, streamKey: string, broadcastUrl: string) => {
              liveStreamClient.startLiveStream(streanUrl, streamKey, broadcastUrl);
            }}
          />
        </>
      )}
      {liveStreamStatus === LiveStreamStatus.InProgress && (
        <IconFont type="icon-live" style={{ position: 'fixed', top: '45px', left: '10px', color: '#f00' }} />
      )} */}
    <LeaveButton onLeaveClick={onLeaveClick} isHost={zmClient.isHost()} onEndClick={onEndClick} />
    {hospitalName === 'Ison Healthcare' && (
        <InviteButton onInviteClick={() => handleOpen()} isHost={zmClient.isHost()} />
      )}
    <InviteModel showModal={showModal} handleClose={handleClose}/>
    
    {isLoading && <LoadingLayer content={loadingText} />}

     <AudioVideoStatisticModal
        visible={statisticVisible}
        setVisible={setStatisticVisible}
        defaultTab={selecetedStatisticTab}
        isStartedAudio={isStartedAudio}
        isMuted={isMuted}
        isStartedVideo={isStartedVideo}
      />

      {recordingIsoStatus === RecordingStatus.Ask && (
        <IsoRecordingModal
          onClick={() => {
            recordingClient?.acceptIndividualRecording();
          }}
          onCancel={() => {
            recordingClient?.declineIndividualRecording();
          }}
        />
      )}
      {!mediaStream?.isSupportVirtualBackground() && (
        <VideoMaskModel  visible={videoMaskVisible} setVisible={setVideoMaskVisible} isMirrored={isMirrored} />
      )}
      </div>
  );
};
export default VideoFooter;
