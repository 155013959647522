// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.join-button {
  color: white;
  background: var(--theme-bg);
  border-radius: 14px;
  font-size: 16px;
  height: 3em;
  margin: 2em auto;
  cursor: pointer;
  border: none;
  font-stretch: expanded;
  transition: filter 0.15s ease-out;
}
.join-button:hover {
  background: var(--theme-bg);
  color: white;
}
.join-button:active {
  filter: brightness(80%);
}`, "",{"version":3,"sources":["webpack://./src/component/pipActivateModel.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,2BAAA;EACA,mBAAA;EACA,eAAA;EACA,WAAA;EACA,gBAAA;EACA,eAAA;EACA,YAAA;EACA,sBAAA;EACA,iCAAA;AACJ;AACI;EACE,2BAAA;EACA,YAAA;AACN;AAEI;EACE,uBAAA;AAAN","sourcesContent":[".join-button {\n    color: white;\n    background: var(--theme-bg);\n    border-radius: 14px;\n    font-size: 16px;\n    height: 3em;\n    margin: 2em auto;\n    cursor: pointer;\n    border: none;\n    font-stretch: expanded;\n    transition: filter 0.15s ease-out;\n  \n    &:hover {\n      background: var(--theme-bg);\n      color: white;\n    }\n  \n    &:active {\n      filter: brightness(80%);\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
