/* eslint-disable prettier/prettier */
import React, { useState, useContext, useRef, useEffect, useCallback, ChangeEvent } from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import { RouteComponentProps, useHistory, Link } from 'react-router-dom';
import ZoomContext from '../../context/zoom-context';
import ZoomMediaContext from '../../context/media-context';
import AvatarActionContext from './context/avatar-context';
import Avatar from './components/avatar';
import VideoFooter from './components/video-footer';
import Pagination from './components/pagination';
import { useCanvasDimension } from './hooks/useCanvasDimension';
import { useGalleryLayout } from './hooks/useGalleryLayout';
import { usePagination } from './hooks/usePagination';
import { useActiveVideo } from './hooks/useAvtiveVideo';
import { useAvatarAction } from './hooks/useAvatarAction';
import { useNetworkQuality } from './hooks/useNetworkQuality';
import ReportBtn from './components/report-btn';
import ShareView from './components/share-view';
import RemoteCameraControlPanel from './components/remote-camera-control';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './video.scss';
import apiService from '../../services/api';
import Swal from 'sweetalert2';
import Modal from '../../component/Model';
import LoadingLayer from '../../component/loading-layer';
import { SELF_VIDEO_ID } from './video-constants';
import PipActivateModel from '../../component/pipActivateModel';
import PipClosedModel from '../../component/pipClosedModel';

declare global {
  interface Window {
    documentPictureInPicture?: {
      requestWindow: (options: { width: number; height: number }) => Promise<Window>;
      window?: Window;
    };
  }
}

interface User {
  name: string;
  gender: string;
  dob: string;
  profile_picture: string;
  email: string;
  address: string;
  city: string;
  state: string;
  postal_code: string;
  phone: string;
  dea_no: string;
  npi_no: string;
  signature: string;
}
interface Prescription {
  drug_name: string;
  dosage: string;
  frequency: string;
  notes: string;
  days: string;
}

interface Notes {
  id: any;
  practitioner_notes: string;
  documentation_notes: string;
  referal_notes: string;
}

interface userID {
  from_user_id: string;
}
interface Specialty {
  name: string;
}
interface Question {
  id: string;
  name: string;
  answer?: string; // Make answer optional if it may not exist initially
  questions: Question[];
}

interface SelectedIdFormatted {
  lab_test_id: string;
}

const VideoContainer: React.FunctionComponent<RouteComponentProps> = (props) => {
  const RHistory = useHistory();
  const zmClient = useContext(ZoomContext);
  const {
    mediaStream,
    video: { decode: isVideoDecodeReady }
  } = useContext(ZoomMediaContext);
  const videoRef = useRef<HTMLCanvasElement | null>(null);
  const shareViewRef = useRef<{ selfShareRef: HTMLCanvasElement | HTMLVideoElement | null }>(null);
  const [isRecieveSharing, setIsRecieveSharing] = useState(false);
  const canvasDimension = useCanvasDimension(mediaStream, videoRef);
  const activeVideo = useActiveVideo(zmClient);
  const { page, pageSize, totalPage, totalSize, setPage } = usePagination(zmClient, canvasDimension);
  const { visibleParticipants, layout: videoLayout } = useGalleryLayout(
    zmClient,
    mediaStream,
    isVideoDecodeReady,
    videoRef,
    canvasDimension,
    {
      page,
      pageSize,
      totalPage,
      totalSize
    }
  );
  console.log(visibleParticipants, 'visibleParticipants');
  window.sessionStorage.setItem('visibleParticipants', visibleParticipants.length.toString());
  const avatarActionState = useAvatarAction(zmClient, visibleParticipants);
  const networkQuality = useNetworkQuality(zmClient);
  const [PdfURL, setPdfURL] = useState('');
  const [ UserAllDetails, SetuserAllDetails] = useState<any>(null);

  useEffect(() => {
    // const intervalId = setInterval(() => {
    //   hideElement();
    // }, 3000);

    // // Cleanup the interval on component unmount
    // return () => clearInterval(intervalId);
  }, []);

  const hideElement = async () => {
    const callID = await apiService.GetCallID();
    const UserDetailsID = UserAllDetails

    if (callID.data.call_detail_id !== '') {
      try {
        const User_Details = await apiService.viewId(
          callID.data.call_detail_id,
          UserDetailsID.data.callData.to_user_id
        );
        // Handle the success condition

        // You can also add other logic here that you want to execute on success
      } catch (error) {
        // Handle the error condition

        console.error('Error fetching user details:', error);
        // You can also add other logic here that you want to execute on error
      }
    } else {
      const urlParams = new URLSearchParams(window.location.search);
      const topic = urlParams.get('topic') ?? ''; 
      const callID = urlParams.get('callID') ?? ''; 

      if (callID !== '') {
        try {
          const User_Details = await apiService.viewId(callID, UserDetailsID.data.to_user_id);

          console.log('User Details:', User_Details);
        } catch (error) {
          console.error('Error fetching user details:', error);
        }
      }
    }
  };


  useEffect(() => {
    const urlParameter = new URLSearchParams(window.location.search);
    console.log(urlParameter);
    const appid = urlParameter.get('APPID') ?? '';
    const baseUrl = urlParameter.get('Production') ?? '';
    console.log(baseUrl, 'baseUrl');
    const newPath = baseUrl.replace('/web/api/', '');
    setPdfURL(newPath);
    console.log(newPath, 'baseUrl');
    window.sessionStorage.setItem('AppID', appid);
    console.log('Appid', appid);
  }, []);

  const [textInput, setTextInput] = useState('');
  const handleInputChange = (event: any) => {
    setTextInput(event.target.value);
  };

  const [attachment, setAttachment] = useState(null);

  const handleFileChange = async (event: any) => {
    const file = event.target.files[0];
  
    if (!file) return; // If no file is selected, return early
  
    setAttachment(file);
    const tempId = Date.now(); // Temporary ID for this file message
    const tempChat = {
      id: tempId,
      sender_id: userId,
      message: '',
      attachement: file.name.endsWith('.pdf') ? 'images/pdf.png' : URL.createObjectURL(file),
      created_at: new Date(),
      sender_name: 'Me',
    };
  
    // Show "Sending..." file temporarily
    setChats((prevChats: any) => [...prevChats, tempChat]);
  
    try {
      const postsData = await apiService.sendAttachmentPat(file, UserAllDetails);
  
      // Ensure postsData and postsData.data are defined before accessing
      if (postsData?.data) {
        // Replace temporary message with actual message from the response
        setChats((prevChats: any) =>
          prevChats.map((chat: any) =>
            chat.id === tempId ? { ...postsData.data[postsData.data.length - 1] } : chat
          )
        );
  
        setAvailableMessages(postsData?.unread_message_count);
      } else {
        console.error('Unexpected response structure:', postsData);
        // Optionally show an error message to the user
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "File Attachment failed",
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            popup: 'small-swal'
          }
        });
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      // Optionally show an error message to the user
    }
  
    // Reset the file input after handling the file
    event.target.value = null;
  };
  
  
  
  const handleSubmit = (event: any) => {
    event.preventDefault();
    const tempId = Date.now(); // Temporary ID for this message
  
    const send = async (textInput: string) => {
      if (textInput.trim() === '') return;
      
      const tempChat = {
        id: tempId,
        sender_id: userId,
        message: 'Sending...', // Temporary message text
        attachement: '',
        created_at: new Date(),
        sender_name: 'Me',
      };
  
      // Show "Sending..." message temporarily
      setChats((prevChats: any) => [...prevChats, tempChat]);
  
      try {
        const postsData = await apiService.sendMessagePat(textInput, UserAllDetails);
        
        // Check if postsData and postsData.data are defined and have the expected structure
        if (postsData?.data?.length > 0) {
          // Replace temporary message with actual message from the response
          setChats((prevChats: any) => prevChats.map((chat: any) =>
            chat.id === tempId ? { ...postsData.data[postsData.data.length - 1] } : chat
          ));
          setAvailableMessages(postsData.unread_message_count); // Adjust this line if needed
        } else {
          // Handle cases where postsData is not in the expected format
          console.error("Invalid response data:", postsData);
          // Optionally, you can show an error message to the user here
        }
      } catch (error) {
        console.error('Error sending message:', error);
        // Optionally, show an error message to the user
      }
    };
  
    send(textInput);
    setTextInput('');
  };
  
  



  interface Drug {
    id: string;
    reg_no: string;
  }
  const [Orders, setOrders] = useState([]);
  const [user, userDetails] = useState<User | null>(null);
  const [patient, PatientDetails] = useState<User | null>(null);
  const [PatientImg, setPatientImg] = useState('');
  const [DoctorImg, setDoctorImg] = useState('');
  const [themeConfig, setThemeConfig] = useState('');
  const [callID, setCallID] = useState<string | null>(null);
  const [meetingID, setMeetingID] = useState('');
  const [chats, setChats] = useState<any>([]);
  const [ChatTrigger, setChatTrigger] = useState(false);
  const [userID, setUserID] = useState<userID | null>(null);
  const [messageCount, setAvailableMessages] = useState(0);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [selectedIdsFormatted, setSelectedIdsFormatted] = useState<SelectedIdFormatted[]>([]);

  const CallCompleateStatus = async () => {
    try {
      const CallStatus = await apiService.GetCallID();
      console.log('call statsi', CallStatus);
      if (CallStatus.call_status === 'completed') {
        const urlParams = new URLSearchParams(window.location.search);
        const baseUrl = urlParams.get('baseUrl') ?? ''; 
        window.location.href = baseUrl;
      }
    } catch (error) {
      console.error('Error fetching Orders:', error);
    }
  };


  useEffect(() => {
    const canvas = videoRef.current;
    console.log('canvas', canvas);
    // Set width and height
    if (canvas) {
      // Set width and height
      canvas.width = 630;
      canvas.height = 450;
    }

    const selectedIdsFormatted = selectedIds.map((selectedId) => ({ lab_test_id: selectedId }));
    console.log(selectedIdsFormatted, 'selectedIdsFormatted');
    setSelectedIdsFormatted(selectedIdsFormatted);


 
    const isForwardCall = async () => {
      try {
        const User_Details = UserAllDetails
        if (User_Details.data.callData.is_call_forward === 'yes') {
          if (!window.sessionStorage.getItem('forward_popup')) {
            Swal.fire({
              title: 'This Call is Forwarded to another Doctor!',
              showDenyButton: false,
              showCancelButton: false,
              confirmButtonText: 'Ok',
              confirmButtonColor: 'var(--theme-bg)', // Set the color for the confirm button
              denyButtonColor: '#A0A2B3',
              allowOutsideClick: false
            }).then((result) => {
              if (result.isConfirmed) {
                window.sessionStorage.setItem('forward_popup', 'true');
              }
            });
          }
        }
      } catch (error) {
        console.error('Error fetching Orders:', error);
      }
    };

    const viewUser = async () => {
      try {
        const User_Details = await apiService.viewUser();
        console.log('User_Details', User_Details);
        if (User_Details.data.callData.call_status === 'completed') {
          setIsLoading(true);
          const urlParams = new URLSearchParams(window.location.search);
          const baseUrl = urlParams.get('baseUrl') ?? ''; 
          window.location.href = baseUrl;
        }
        AllchatHis(User_Details)
        themeConfiguration(User_Details)
        hospitalLogo(User_Details)
        SetuserAllDetails(User_Details)
        userDetails(User_Details.data.toUser);
        setPatientImg(User_Details.data.fromUser.profile_picture);
        setDoctorImg(User_Details.data.toUser.profile_picture);
        window.sessionStorage.setItem('userID', User_Details.data.callData.from_user_id);
        setUserID(User_Details.data.callData.from_user_id);
        const userId = User_Details.data.callData.from_user_id;
        // setChats(User_Details.data.chatHistory);
        document.title = User_Details.data.hospital_info.abbrevation;
        PatientDetails(User_Details.data.fromUser);
        // window.sessionStorage.setItem('userId', User_Details.data.callData.from_user_id);
        setInterval(() => AllchatHis(User_Details), 10000); 
      } catch (error) {
        console.error('Error fetching Orders:', error);
      }
    };

      
    const AllchatHis = async (User_Details: any) => {
      try {
        const chatHis = await apiService.chatHistory(User_Details);
        console.log('chatHis', chatHis);
        if (chatHis?.data) {
          setChats(chatHis.data);
          setAvailableMessages(chatHis.unread_message_count);
        } else {
          console.error("Invalid chat history response:", chatHis);
        }              
      } catch (error) {
        console.error('Error fetching chat history:', error);
      }
    };
    

    const urlParams = new URLSearchParams(window.location.search);
    const topic = urlParams.get('topic') ?? ''; 
    const sessionKey = urlParams.get('sessionKey') ?? ''; 
    const userRole = urlParams.get('user_role') ?? ''; 
    const callID = urlParams.get('callID') ?? ''; 
    const meetingID = urlParams.get('meetingID') ?? ''; 
    const meetID = urlParams.get('meetID') ?? ''; 
    const baseUrl = urlParams.get('baseUrl') ?? ''; 

    // fetchOrders();
    viewUser();
    // setInterval(viewUser, 50000);
    isForwardCall();
    CallCompleateStatus()
    setInterval(() => CallCompleateStatus(), 10000); 
    // chatHis();
    // videoHistory();
    // hospitalConfiguration();
    // AvailableDoctors();
  }, [selectedIds]);

  const [alert, setAlert] = useState(false); 
  const [alertContent, setAlertContent] = useState(''); 
  const themeConfiguration = async (User_Details: any) => {
    try {
      const theme_Config = await apiService.themeConfiguration(User_Details);
      console.log(theme_Config, 'theme_Config');
      setThemeConfig(theme_Config.data.video_font_icon);
      if (theme_Config.data.alert === 'on') {
        setAlert(true)
        setAlertContent(theme_Config.data.alert_content)
        // Swal.fire({
        //   position: 'top',
        //   title: theme_Config.data.alert_content,
        //   showConfirmButton: false,
        //   showCloseButton: true, // Enable the close button
        //   customClass: {
        //     popup: 'custom-swal-popup',
        //     title: 'custom-swal-title',
        //     closeButton: 'custom-swal-close', // Custom class for the close button (optional)
        //   },
        // });
      }
      const root = document.documentElement;
      root.style.setProperty('--theme-bg', theme_Config.data.video_font_icon);
    } catch (error) {
      console.error('Error fetching Orders:', error);
    }
  };



  const hospitalLogo = async (User_Details: any) => {
    try {
      console.log('User_Details', User_Details);
      const logo = await apiService.hosLogo(User_Details.data.callData.hospital_id);
      setLOGO(logo.data[0]);
      setProjectURL(logo.zoom_url);
      console.log(LOGO, 'LOGO');
    } catch (error) {
      console.error('Error fetching Orders:', error);
    }
  };
 
  const [chat, setChatsHide] = useState(true);
  const chatBoxInputRef = useRef<HTMLInputElement>(null);
  const userId = window.sessionStorage.getItem('userID');
  const historyS = useHistory();
  const messageContainerRef = useRef<HTMLDivElement>(null);
  const previousChatsLengthRef = useRef<number>(chats.length);
  
  useEffect(() => {
    if (chats.length > previousChatsLengthRef.current) {
      setTimeout(() => {
        if (
          messageContainerRef.current &&
          messageContainerRef.current.scrollHeight > messageContainerRef.current.clientHeight
        ) {
          const element = messageContainerRef.current;
          element.scrollTop = element.scrollHeight;
        }
      }, 100); 
    }
  
    previousChatsLengthRef.current = chats.length;
  }, [chats]);


  const urlParams = new URLSearchParams(window.location.search);
  const BASEURL = urlParams.get('baseUrl') ?? '';

  const [LOGO, setLOGO] = useState('');
  const [ProjectURL, setProjectURL] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('End Session...');

  const LeaveRoom = async (userID: any) => {
    setLoadingText('Leaving the room...')
    setIsLoading(true);
    try {
      const User_Details = UserAllDetails
      const leave_room = await apiService.LeaveRoom(User_Details.data.callData.id);
      if (leave_room !== '') {
        const urlParameter = new URLSearchParams(window.location.search);
        const baseURL = urlParameter.get('baseUrl') ?? '';
        const patientID = urlParams.get('PatientID') || urlParams.get('PatientID') || '';
        const url = new URL(baseURL);
        if (patientID) {
          url.searchParams.append('id', patientID);
        }
  
        window.location.href = url.toString();
      }
    } catch (error) { 
      const urlParameter = new URLSearchParams(window.location.search);
      const baseURL = urlParameter.get('baseUrl') ?? '';
      const patientID = urlParams.get('PatientID') || urlParams.get('PatientID') || '';
      const url = new URL(baseURL);
      if (patientID) {
        url.searchParams.append('id', patientID);
      }

      window.location.href = url.toString();
    }
  };

  const [isChatVisible, setIsChatVisible] = useState(false);

  useEffect(() => {
    if (isChatVisible && chatBoxInputRef.current) {
      chatBoxInputRef.current.focus();
    }
  }, [isChatVisible]);

  const toggleChatVisibility = () => {


    setIsChatVisible((prevState) => !prevState);


  };

  function formatTime(dateString: any) {
    const date = new Date(Date.parse(dateString));
  
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
  
    const ampm = hours >= 12 ? 'PM' : 'AM';
  
    hours = hours % 12 || 12;
    
    return `${hours}:${minutes} ${ampm}`;
  }
  


  const isDocumentPictureInPictureSupported = () => 'documentPictureInPicture' in window;

  let pipWindow: any = null;
  let originalParent: any = null;
  let originalNextSibling: any = null;
  let userInteracted = false; // Flag to track user interaction
  
  const startPictureInPicture = async () => {
    if (
      isDocumentPictureInPictureSupported() &&
      window.documentPictureInPicture &&
      !window.documentPictureInPicture.window
    ) {
      try {
        pipWindow = await window.documentPictureInPicture.requestWindow({
          height: 512,
          width: 512,
        });
  
        const thisDocument = window.document;
        const pipDocument = pipWindow.document;
  
        // Create a loader element and add it to the PiP window
        const loader = pipDocument.createElement('div');
        loader.classList.add('loader'); // Ensure you have a class that styles the loader
        pipDocument.body.appendChild(loader);
        const loaderStyle = pipDocument.createElement('style');
        loaderStyle.textContent = `
          .loader {
            display: flex;
            align-items: center;
            justify-content: center;
            position: fixed;
            top: 50%;
            left: 50%;
          }
        `;
        pipDocument.head.appendChild(loaderStyle);
        // Copy stylesheets to the PiP window
        const styleSheets = Array.from(thisDocument.styleSheets);
        styleSheets.forEach((styleSheet) => {
          try {
            if (styleSheet.href) {
              const newLinkElement = pipDocument.createElement('link');
              newLinkElement.rel = 'stylesheet';
              newLinkElement.href = styleSheet.href;
              pipDocument.head.appendChild(newLinkElement);
            } else if (styleSheet.ownerNode) {
              const newStyleElement = pipDocument.createElement('style');
              newStyleElement.textContent = styleSheet.ownerNode.textContent;
              pipDocument.head.appendChild(newStyleElement);
            }
          } catch (error) {
            console.error('Error copying stylesheet:', error);
          }
        });
  
        // Copy the video-player-container element to the PiP window after 2 seconds
        setTimeout(() => {
          const videoContainer = thisDocument.querySelector('.video-player-container');
          if (videoContainer) {
            originalParent = videoContainer.parentNode;
            originalNextSibling = videoContainer.nextSibling;
  
            pipDocument.body.appendChild(videoContainer);
          } else {
            console.error('No element found with the selector .video-player-container');
            return;
          }
  
          // Hide the loader after 2 seconds
          loader.style.display = 'none';
  
          const movedElement = thisDocument.createElement('p');
          movedElement.classList.add('text-center');
          movedElement.id = 'msg-moved-to-pip';
          movedElement.textContent = 'Video has been moved to picture-in-picture window';
          thisDocument.body.appendChild(movedElement);
        }, 2000);
  
        pipWindow.addEventListener('pagehide', () => {
          const videoContainer = pipDocument.querySelector('.video-player-container');
          if (videoContainer && originalParent) {
            originalParent.insertBefore(videoContainer, originalNextSibling);
            originalParent = null;
            originalNextSibling = null;
            thisDocument.querySelector('p#msg-moved-to-pip')?.remove();
          } else {
            console.error('No element found with the selector .video-player-container in PiP window');
          }
          pipWindow = null;
          setTimeout(() => {
            toggleChatVisibility();
          }, 2000);
        });
      } catch (error) {
        console.error('Error starting Picture-in-Picture:', error);
      }
    } else {
      console.error('Document PiP is not supported, or a PiP window is already open');
    }
  };
  
  
  const closePictureInPicture = () => {
    if (pipWindow?.close) {
      pipWindow.close();
      pipWindow = null;
    }
  };
  
  // Handle user interaction
  document.addEventListener('click', () => {
    userInteracted = true; // Set the flag when user interacts with the app
    if (document.visibilityState === 'hidden') {
      // Start PiP when the user clicks while the document is not visible
      startPictureInPicture();
    }
  });
  
  // Handle tab visibility changes
  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'hidden' && userInteracted) {
      // Add a short timeout to allow for any pending interactions
      setTimeout(() => {
        startPictureInPicture();
      }, 300); // 300 ms delay
    } else if (document.visibilityState === 'visible') {
      // Close PiP when the tab is visible
      closePictureInPicture();
      handlePipModal()
    }
  });
  
  

const [showModal, setShowModal] = useState(false);
const [showPipModal, setpipShowModal] = useState(false);
  const [fileUrl, setFileUrl] = useState('');

  const handleOpen = (url: any) => {
    setFileUrl(url);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setFileUrl(''); // Reset the URL when closing
  };
  const handlePipModal = () => {
    setpipShowModal(true);
  };
  const handlePipClose = () => {
    setpipShowModal(false);
  };
  const currentUserIndex = visibleParticipants.findIndex(
    (user) => user.userId === zmClient.getCurrentUserInfo()?.userId
  );
  let selfVideoLayout = null;
  if (currentUserIndex > -1) {
    const item = videoLayout[currentUserIndex];
    if (item && canvasDimension) {
      selfVideoLayout = { ...item, y: canvasDimension.height - item.y - item.height };
    }
  }
  
  return ( 

       <div className="main-body" style={{backgroundColor:'#f6f6f6'}}>
       {isLoading && <LoadingLayer content={loadingText} />}

      <nav className="navbar navbar-expand-lg navbar-light custom-body-bg shadow" style={{backgroundColor:'white'}}>
        <div className="container">
          <a className="navbar-brand" href="#">
            <img src={LOGO} crossOrigin="anonymous" className="Logo" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0 align-items-center">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  aria-current="page"
                  href="#"
                  onClick={() => LeaveRoom(window.sessionStorage.getItem('userID'))}
                >
                  Leave Room
                </a>
              </li>
              <li className="nav-item">
                {patient ? (
                  <a className="nav-link" href="#">
                    <div className="user-profile">
                      <img crossOrigin="anonymous" src={patient.profile_picture} className="rounded-circle" />
                      <div className="profile">
                        <h4>{patient.name} </h4>
                        {/* <p>Profile</p> */}
                      </div>
                    </div>
                  </a>
                ) : (
                  <p>...</p>
                )}{' '}
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {/* {alert && (
        <label className="custom-label">
          {alertContent}
        </label>
      )} */}
      <div className="container-fluid education-details-page" >
        <div className="row" style={{ display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '86vh',
  paddingTop:'15px'}}>
          <div
            className={`${isChatVisible ? 'col-xl-9 col-lg-9' : 'col-xl-12 col-lg-12'} col-md-12 col-sm-12 col-12 shadow-sm bg-white`}
            style={{ height: '570px' }}
          >
              {window.sessionStorage.getItem('patientloading') === 'true' && (
      <div style={{
        display: 'flex',
        flexDirection: 'column',  
        justifyContent: 'center',   
        alignItems: 'center',       
        height: '100%'            
      }}>
          <img src="/icon/load.gif" width="100px" alt="Loading..." style={{ marginBottom: '16px' }} />
      <h6>Loading video</h6>
      </div>
  )}
         {window.sessionStorage.getItem('patientloading') === 'false' &&   
          <div className="video-main-tab doctor-section-video position-relative">
              <div>
                <ShareView ref={shareViewRef} onRecieveSharingChange={setIsRecieveSharing} />
                <div
                  className={classnames('video-container video-player-container', {
                    'video-container-in-sharing': isRecieveSharing
                  })}
                >
                    <canvas
                    className="video-canvas"
                    id="video-canvas"
                    style={isChatVisible ? { width: '100%', height: '100%' } : { width: '100%', height: '482px' }}
                    ref={videoRef}
                  />
                  <AvatarActionContext.Provider value={avatarActionState}>
                    <ul className="avatar-list">
               
                  
                      {visibleParticipants.map((user, index) => {
                        if (index > videoLayout.length - 1) {
                          return null;
                        }

                        const dimension = videoLayout[index];
                        const { width, height, x, y } = dimension;
                        const { height: canvasHeight } = canvasDimension;
                        return (
                          <Avatar
                            participant={user}
                            key={user.userId}
                            isActive={activeVideo === user.userId}
                            networkQuality={networkQuality[`${user.userId}`]}
                            style={{
                              width: `${width}px`,
                              height: `${height}px`,
                              top: `${canvasHeight - y - height}px`,
                              left: `${x}px`
                            }}
                          />
                        );
                      })}
                    </ul>
                    <RemoteCameraControlPanel />
                  </AvatarActionContext.Provider>
                </div>
                <VideoFooter
                  className="video-operations p-5"
                  sharing
                  onChatClick={toggleChatVisibility}
                  selfShareCanvas={shareViewRef.current?.selfShareRef}
                  chats={chats}
                  senderId={userId}
                />
              </div>
            </div>}
             {/* <button onClick={startPictureInPicture}>Start PiP</button>  */}

          </div>
          {isChatVisible && (
            <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mt-xl-0 mt-lg-0 mt-md-5 mt-sm-5 mt-5 shadow-sm bg-white">
              <div className="d-flex gap-3 video-tab-details-section">
                <div className="tab-content bg-white rounded" id="nav-video-tab-details-section">
                  {chat && (
                    <div
                      className="tab-pane fade active show"
                      id="nav-profile2"
                      role="tabpanel"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Chat"
                      aria-labelledby="nav-profile-tab"
                    >
                      <h5 className='py-2' style={{textAlign:'start'}}>Chat({messageCount||0})</h5>
                      <div className="video-tab-record-ditails pe-2">
                        <div className="chat-area chat-area-340">
                          <div className="chatbox">
                            <div className="modal-dialog-scrollable">
                              <div className="modal-content">
                                {/* <div className="msg-head d-none">
                                  <div className="row">
                                    <div className="col-8">
                                      <div className="doctor-details d-flex justify-content-start align-items-center">
                                        <div className="doctor-content">
                                          <h4>Dr. Rainer Mere</h4>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-4">
                                      <ul className="moreoption">
                                        <li className="navbar nav-item dropdown">
                                          <a
                                            className="nav-link dropdown-toggle"
                                            href="#"
                                            role="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                          >
                                            <i className="fa fa-ellipsis-v" aria-hidden="true" />
                                          </a>
                                          <ul className="dropdown-menu">
                                            <li>
                                              <a className="dropdown-item" href="#">
                                                Action
                                              </a>
                                            </li>
                                            <li>
                                              <a className="dropdown-item" href="#">
                                                Another action
                                              </a>
                                            </li>
                                            <li>
                                              <hr className="dropdown-divider" />
                                            </li>
                                            <li>
                                              <a className="dropdown-item" href="#">
                                                Something else here
                                              </a>
                                            </li>
                                          </ul>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div> */}
                                <div className="modal-body" ref={messageContainerRef}>
                                  <div className="msg-body">
                                  <ul>
                                              {chats.map((chat: any) => (
                                                <li
                                                  className={chat.sender_id === userId ? 'repaly' : 'sender'}
                                                  key={chat.id}
                                                >
                                                 
                                                  <div className="user-profile-cont">
                                                   
                                                    <img
                                                      src={chat.sender_id === userId ? PatientImg : DoctorImg}
                                                      alt="User Image"
                                                      crossOrigin="anonymous"
                                                      className="user-image"
                                                    />
                                                   {/* <span className="time"> 
  <span style={{ marginRight: '235px' }} className={chat.sender_id === userId ? '' : 'd-none'}>
    from <span className='user-text'>{chat.sender_name}</span>
  </span> 
  {formatTime(chat.created_at)} 
  <span style={{ marginLeft: '195px' }} className={chat.sender_id === userId ? 'd-none' : ''}>
    from <span className='user-text'>{chat.sender_name}</span>
  </span>
</span> */}
                                                   <div className='d-flex justify-content-between w-full' style={{width: "-webkit-fill-available"}}>
 
  {chat.sender_id === userId ? (
  <>
    <span className="time">{formatTime(chat.created_at)}</span>
    <span className="time">  <span className='user-text'>Me</span></span>
  </>
) : (
  <>
    <span className="time">  <span className='user-text'>{chat.sender_name}</span></span>
    <span className="time">{formatTime(chat.created_at)}</span>
  </>
)}


 </div>
                                                    
                                                  </div>
                                                  {chat.message !== '' ? (
                                                    <p>{chat.message}</p>
                                                  ) : (
                                                    <div className="user-attachment">
                                                    <a
                                                      href={chat.attachement}
                                                      target="_blank"
                                                      rel="noreferrer"
                                                      onClick={(e) => {
                                                        e.preventDefault(); // Prevent default link action
                                                        handleOpen(chat.attachement); // Open modal with file URL
                                                      }}
                                                    >
                                                      {chat.attachement ? (
                                                        chat.attachement.endsWith('.pdf') ? (
                                                          <img
                                                            src="images/pdf.png"
                                                            alt="PDF Icon"
                                                            className="pdf-icon"
                                                          />
                                                        ) : (
                                                          <img
                                                            src={chat.attachement}
                                                            alt="Chat Attachment"
                                                            crossOrigin="anonymous"
                                                            className="chat_attachment"
                                                          />
                                                        )
                                                      ) : null}
                                                    </a>
                                                    <Modal showModal={showModal} handleClose={handleClose} fileUrl={fileUrl} />
                                                  </div>
                                                  )}
                                                </li>
                                              ))}
                                            </ul>
                                    {/* <ul>
                                      {chats.map((chat: any) => (
                                        <li className={chat.sender_id === userId ? 'repaly' : 'sender'} key={chat.id}>
                                          {chat.message !== '' ? (
                                            <p>{chat.message}</p>
                                          ) : (
                                            <div className="user-attachment">
                                              <a
                                                href={chat.attachement}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                {chat.attachement ? (
                                                  chat.attachement.endsWith('.pdf') ? (
                                                    <img src="images/pdf.png" alt="PDF Icon" className="pdf-icon" />
                                                  ) : (
                                                    <img
                                                      src={chat.attachement}
                                                      alt="Chat Attachment"
                                                      crossOrigin="anonymous"
                                                      className="chat_attachment"
                                                    />
                                                  )
                                                ) : null}
                                              </a>
                                            </div>
                                          )}
                                          <div className="user-profile-cont">
                                            <img
                                              src={chat.sender_id === userId ? PatientImg : DoctorImg}
                                              alt="User Image"
                                              crossOrigin="anonymous"
                                              className="user-image"
                                            />
                                            <span className="time">{chat.created_at}</span>
                                          </div>
                                        </li>
                                      ))}
                                    </ul> */}
                                  </div>
                                </div>
                                <div className="send-box">
                                <form onSubmit={handleSubmit}>
  <div className="send-msg-wrap">
    <div className="button-wrapper">
      <span className="label">
        <img className="img-fluid" src="/icon/upload.svg" alt="image title" />
      </span>
      <input
        type="file"
        onChange={handleFileChange}
        name="upload"
        id="upload"
        className="upload-box"
        placeholder="Upload File"
        aria-label="Upload File"
        style={{ pointerEvents: 'auto' }} // Ensure the file input is clickable
      />
    </div>
    <input
      type="text"
      className="form-control"
      value={textInput}
      onChange={handleInputChange}
      aria-label="message…"
      placeholder="Type message…"
      style={{ pointerEvents: 'auto' }} // Ensure the text input is clickable
    />
  </div>
  <button type="submit" disabled={textInput === ''}>
    <img src="/icon/send.png" alt="Send" />
  </button>
</form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <PipActivateModel/>
      <PipClosedModel showPipModal={showPipModal} handlePipClose={handlePipClose} />

    </div>

  
  );
};

export default VideoContainer;
