import React, { useState, useRef, useCallback } from 'react';
import Draggable from 'react-draggable';
import { Modal } from 'antd';

interface DraggableModalProps {
  title: string | React.ReactNode;
  visible: boolean;
  children: React.ReactNode;
  onClose: () => void;
  onOk?: () => void;
  onCancel?: () => void;
  okText?: string;
  cancelText?: string;
  width?: number;
}

interface DragBound {
  left: number;
  top: number;
  right: number;
  bottom: number;
}

const DraggableModal = (props: DraggableModalProps) => {
  const { title, visible, children, onClose, onCancel, onOk, okText, cancelText, width } = props;
  const [disabled, setDisabled] = useState<boolean>(true);
  const [bounds, setBounds] = useState<DragBound>({
    left: 0,
    top: 0,
    right: 0,
    bottom: 0
  });
  const draggleRef = useRef<HTMLDivElement>(null);

  const onModalMouseOver = useCallback(() => {
    if (disabled) {
      setDisabled(false);
    }
  }, [disabled]);

  const onModalMouseOut = useCallback(() => {
    setDisabled(true);
  }, []);

  const onDragStart = useCallback((event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (targetRect) {
      setBounds({
        left: -targetRect.left + uiData?.x,
        right: clientWidth - (targetRect.right - uiData?.x),
        top: -targetRect.top + uiData?.y,
        bottom: clientHeight - (targetRect.bottom - uiData?.y)
      });
    }
  }, []);

  const isShowFooter = !!onOk;
  const restModalProps = isShowFooter
    ? { okText, cancelText, onOk, onCancel }
    : { footer: null };

  // Move the modalRender logic to a separate function
  const renderModal = (modal: React.ReactNode) => (
    <Draggable disabled={disabled} bounds={bounds} onStart={onDragStart} nodeRef={draggleRef}>
      <div ref={draggleRef}>{modal}</div>
    </Draggable>
  );

  return (
    <Modal
      title={
        <div className="breakout-room-title" onMouseOver={onModalMouseOver} onMouseOut={onModalMouseOut}>
          {title}
        </div>
      }
      open={visible}
      modalRender={renderModal}
      onCancel={onClose}
      width={width || 600}
      className="room-modal"
      {...restModalProps}
    >
      {children}
    </Modal>
  );
};

export default DraggableModal;
