// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav {
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navhome {
  margin-left: 5vw;
  display: flex;
  align-items: center;
}

.navhome span {
  color: #747487;
  display: inline-block;
  padding-left: 1rem;
  margin-left: 1rem;
  border-left-width: 1px;
  border-color: #747487;
}

.navdoc {
  flex-grow: 1;
}

.navleave {
  margin-right: 2rem;
}

.navdoc span {
  margin: 0 10px 0 0;
  padding-left: 1rem;
  border-left-width: 1px;
  border-color: #747487;
}

.home {
  padding-top: 10vh;
}
.home h1 {
  font-size: 32px;
  margin-bottom: 20px;
}
.home .feature-entry {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.home .feature-entry .entry-item {
  margin: 0 20px 50px 0;
}
.home .feature-entry .entry-item .ant-card-cover {
  padding-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/feature/patienthome/home.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AACF;;AACA;EACE,gBAAA;EACA,aAAA;EACA,mBAAA;AAEF;;AACA;EACE,cAAA;EACA,qBAAA;EACA,kBAAA;EACA,iBAAA;EACA,sBAAA;EACA,qBAAA;AAEF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAGA;EACE,kBAAA;EACA,kBAAA;EACA,sBAAA;EACA,qBAAA;AAAF;;AAKA;EACE,iBAAA;AAFF;AAGE;EACE,eAAA;EACA,mBAAA;AADJ;AAGE;EACE,aAAA;EACA,eAAA;EACA,6BAAA;AADJ;AAEI;EACE,qBAAA;AAAN;AACM;EACE,iBAAA;AACR","sourcesContent":[".nav {\n  height: 10vh;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n.navhome {\n  margin-left: 5vw;\n  display: flex;\n  align-items: center;\n}\n\n.navhome span {\n  color: #747487;\n  display: inline-block;\n  padding-left: 1rem;\n  margin-left: 1rem;\n  border-left-width: 1px;\n  border-color: #747487;\n}\n\n\n.navdoc {\n  flex-grow: 1;\n}\n\n.navleave {\n  margin-right: 2rem;\n}\n\n\n.navdoc span {\n  margin: 0 10px 0 0;\n  padding-left: 1rem;\n  border-left-width: 1px;\n  border-color: #747487;\n}\n\n\n\n.home {\n  padding-top: 10vh;\n  h1{\n    font-size: 32px;\n    margin-bottom: 20px;\n  }\n  .feature-entry {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-around;\n    .entry-item{\n      margin: 0 20px 50px 0;\n      .ant-card-cover {\n        padding-top:20px\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
