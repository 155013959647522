import { getExploreName } from '../utils/platform';

export const devConfig = {
  sdkKey: 'OSzs-rs3Rbi_pwS75-WZ-Q',
  sdkSecret: 'UriJRiUe1JTAj9DSCNetvPt5c4e3kVL48h6V',
  webEndpoint: 'zoom.us',
  topic: 'test',
  name: 'Gracy',
  password: 'Test@1234',
  sessionKey: '',
  userIdentity: '',
  user_role: '',
  // The user role. 1 to specify host or co-host. 0 to specify participant, Participants can join before the host. The session is started when the first user joins. Be sure to use a number type.
  role: 1
};
