import React from 'react'
import { useHistory } from 'react-router-dom';

function Previews() {
    const history = useHistory();

    const handleNavigation = () => {
        history.goBack();
      };
    
  return (
          <button  onClick={handleNavigation} className="btn btn-primary mt-2 back-button">Back</button>

  )
}

export default Previews
