import type { MenuProps } from 'antd';

export type MenuItem = Required<MenuProps>['items'][number];

type MenuItemType = 'group' | 'divider' | undefined;

export const getAntdItem = (
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: MenuItemType
): MenuItem => {
  const item: MenuItem = {
    key,
    icon,
    children,
    label,
    type
  };

  return item;
};

export const getAntdDropdownMenu = (
  items: MenuItem[],
  onClick: (payload: { key: any }) => void,
  clzName?: string
): MenuProps => {
  return {
    items,
    onClick,
    theme: 'dark',
    className: clzName ?? 'vc-dropdown-menu'
  };
};
