// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.report-button {
  position: absolute;
  top: 5px;
  left: 10px;
}

#jsmediaversion {
  position: absolute;
  top: 15px;
  left: 45px;
  color: red;
}

#report-title {
  color: white;
  position: absolute;
  top: 40px;
  left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/feature/video/components/report-btn.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,QAAA;EACA,UAAA;AACJ;;AACA;EACI,kBAAA;EACA,SAAA;EACA,UAAA;EACA,UAAA;AAEJ;;AAAA;EACI,YAAA;EACA,kBAAA;EACA,SAAA;EACA,UAAA;AAGJ","sourcesContent":[".report-button{\n    position: absolute;\n    top: 5px;\n    left: 10px;\n}\n#jsmediaversion {\n    position: absolute;\n    top: 15px;\n    left: 45px;\n    color: red;\n}\n#report-title{\n    color:white;\n    position: absolute;\n    top: 40px;\n    left: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
