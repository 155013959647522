// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-container {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
}
.chat-container .chat-wrap {
  width: 60vw;
  max-width: 800px;
  height: 80vh;
  background: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  padding: 20px 0;
}
.chat-container .chat-wrap h2 {
  height: 45px;
  line-height: 45px;
}
.chat-container .chat-wrap .chat-message-wrap {
  flex-grow: 1;
  overflow-y: auto;
  border-bottom: 1px solid #eee;
}
.chat-container .chat-wrap .chat-message-box {
  height: 100px;
  box-sizing: border-box;
  padding: 0 5px;
}
.chat-container .chat-wrap .chat-message-box > textarea {
  padding: 5px 20px;
  height: 100%;
  width: 100%;
  resize: none;
}
.chat-container .chat-disabled {
  height: 155px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  flex-shrink: 0;
}`, "",{"version":3,"sources":["webpack://./src/feature/command/command.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,8BAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACF;AAAE;EACE,WAAA;EACA,gBAAA;EACA,YAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,0CAAA;EACA,sBAAA;EACA,eAAA;AAEJ;AADI;EACE,YAAA;EACA,iBAAA;AAGN;AADI;EACE,YAAA;EACA,gBAAA;EACA,6BAAA;AAGN;AADI;EACE,aAAA;EACA,sBAAA;EACA,cAAA;AAGN;AAFM;EACE,iBAAA;EACA,YAAA;EACA,WAAA;EACA,YAAA;AAIR;AAAE;EACE,aAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;EACA,cAAA;AAEJ","sourcesContent":[".chat-container {\n  width: 100vw;\n  height: 100vh;\n  background: rgba(0,0,0,0.6);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  .chat-wrap {\n    width: 60vw;\n    max-width: 800px;\n    height: 80vh;\n    background: #fff;\n    display: flex;\n    flex-direction: column;\n    border-radius: 16px;\n    box-shadow: 0 10px 20px rgba(0,0,0,0.5);\n    box-sizing: border-box;\n    padding: 20px 0;\n    h2{\n      height: 45px;\n      line-height: 45px;\n    }\n    .chat-message-wrap {\n      flex-grow: 1;\n      overflow-y: auto;\n      border-bottom: 1px solid #eee;\n    }\n    .chat-message-box {\n      height: 100px;\n      box-sizing: border-box;\n      padding: 0 5px;\n      >textarea {\n        padding: 5px 20px;\n        height: 100%;\n        width: 100%;\n        resize: none;\n      }\n    }\n  }\n  .chat-disabled {\n    height: 155px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    font-size: 18px;\n    flex-shrink: 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
