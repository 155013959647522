/* eslint-disable prettier/prettier */
import axios from 'axios';
import React from 'react';
import useFcmToken from '../hooks/useFCMToken';

const urlParams = new URLSearchParams(window.location.search);
const appId = urlParams.get('APPID');
const Production = urlParams.get('Production');

let API_URL = 'https://uae-saas-api.instapract.ae/web/api/';

if (Production) {
  API_URL = Production;
} else {
  API_URL = 'https://uae-saas-api.instapract.ae/web/api/';
}

// const useApiService = () => {
//   const [appId, setAppId] = useState('');

//   useEffect(() => {
//     const urlParams = new URLSearchParams(window.location.search);
//     const appIdFromParams = urlParams.get('APPID');

//     // Set the APPID in state
//     if (appIdFromParams) {
//       setAppId(appIdFromParams);
//     }
//   }, []);

//   const api = axios.create({
//     baseURL: API_URL,
//     timeout: 10000, // 10 seconds
//     headers: {
//       'Content-Type': 'application/json',
//       APPID: appId,
//       LANGUAGE: 'da315627-3ece-2016-c628-b61dc5ee9be0',
//       Authorization: 'Bearer C0j_Q',
//       'Access-Control-Allow-Headers': '*',
//       'Access-Control-Allow-Origin': '*',
//       'Access-Control-Allow-Credentials': 'true',
//       'Access-Control-Allow-Methods': 'POST'
//       // Add any other headers here if required
//     }
//   });

//   return {
//     api,
//     api_
//   };
// };

const api = axios.create({
  baseURL: API_URL,
  timeout: 10000, // 10 seconds
  headers: {
    'Content-Type': 'application/json',
    APPID: appId,
    LANGUAGE: 'da315627-3ece-2016-c628-b61dc5ee9be0',
    Authorization: 'Bearer C0j_Q',
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': 'true',
    'Access-Control-Allow-Methods': 'POST'
    // Add any other headers here if required
  }
});

const api_ = axios.create({
  baseURL: API_URL,
  timeout: 10000, // 10 seconds
  headers: {
    'Content-Type': 'application/json',
    APPID: appId,
    LANGUAGE: 'da315627-3ece-2016-c628-b61dc5ee9be0',
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': 'true',
    'Access-Control-Allow-Methods': 'POST'
    // Add any other headers here if required
  }
});

const sendMessage = async (message: any, User_Details: any) => {
  let InstantChat;
  // InstantChat = {
  //   receiver_id: 'cc481c98-042d-51fe-8652-01a62c195db6',
  //   message: 'see the attachment',
  //   chat_from: 'chat',
  //   sender_id: '4184c715-147b-0ec2-7022-cccef3bafb13'
  // };

  const formData = new FormData();

  formData.append('InstantChat[receiver_id]', User_Details.data.callData.from_user_id);
  formData.append('InstantChat[message]', message);
  formData.append('InstantChat[attachement]', '');
  formData.append('InstantChat[chat_from]', 'chat');
  formData.append('InstantChat[sender_id]', User_Details.data.callData.to_user_id);
  formData.append('InstantChat[calldetail_id]', User_Details.data.callData.id);

  const config = {
    headers: { 'content-type': 'multipart/form-data' }
  };

  try {
    const response = await api.post('/chat/send-message-new', formData, config);
    return response.data;
  } catch (error) {
    console.error('Error creating post:', error);
    // throw error;
  }
};

const sendMessagePat = async (message: any, User_Details: any) => {
  let InstantChat;
  // InstantChat = {
  //   receiver_id: 'cc481c98-042d-51fe-8652-01a62c195db6',
  //   message: 'see the attachment',
  //   chat_from: 'chat',
  //   sender_id: '4184c715-147b-0ec2-7022-cccef3bafb13'
  // };

  const formData = new FormData();

  formData.append('InstantChat[receiver_id]', User_Details.data.callData.to_user_id);
  formData.append('InstantChat[message]', message);
  formData.append('InstantChat[attachement]', '');
  formData.append('InstantChat[chat_from]', 'chat');
  formData.append('InstantChat[sender_id]', User_Details.data.callData.from_user_id);
  formData.append('InstantChat[calldetail_id]', User_Details.data.callData.id);

  const config = {
    headers: { 'content-type': 'multipart/form-data' }
  };

  try {
    const response = await api.post('/chat/send-message-new', formData, config);
    return response.data;
  } catch (error) {
    console.error('Error creating post:', error);
    // throw error;
  }
};

const sendAttachmentDoc = async (file: any, User_Details: any) => {
  const formData = new FormData();
  formData.append('InstantChat[receiver_id]', User_Details.data.callData.from_user_id);
  formData.append('InstantChat[attachement]', file);
  formData.append('InstantChat[message]', '');
  formData.append('InstantChat[chat_from]', 'chat');
  formData.append('InstantChat[sender_id]', User_Details.data.callData.to_user_id);
  formData.append('InstantChat[calldetail_id]', User_Details.data.callData.id);

  const config = {
    headers: { 'Content-Type': 'multipart/form-data' }
  };

  try {
    const response = await api.post('/chat/send-message-new', formData, config);
    return response.data;
  } catch (error) {
    console.error('Error creating post:', error);
    // throw error;
  }
};

const sendAttachmentPat = async (file: any, User_Details: any) => {
  const formData = new FormData();
  formData.append('InstantChat[receiver_id]', User_Details.data.callData.to_user_id);
  formData.append('InstantChat[attachement]', file);
  formData.append('InstantChat[message]', '');
  formData.append('InstantChat[chat_from]', 'chat');
  formData.append('InstantChat[sender_id]', User_Details.data.callData.from_user_id);
  formData.append('InstantChat[calldetail_id]', User_Details.data.callData.id);

  const config = {
    headers: { 'Content-Type': 'multipart/form-data' }
  };

  try {
    const response = await api.post('/chat/send-message-new', formData, config);
    return response.data;
  } catch (error) {
    console.error('Error creating post:', error);
    // throw error;
  }
};

const clinicalOrder = async () => {
  try {
    const response = await api.get('/general/clinical-order-list');
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
    // throw error;
  }
};

const videoHistory = async () => {
  const viewIUser = await apiService.viewUser();
  let search_key;
  search_key = {
    id: viewIUser.data.callData.from_user_id
  };
  try {
    const response = await api.post('video/history-new', search_key);
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
    // throw error;
  }
};

const hospitalConfiguration = async (User_Details: any) => {
  let search_key;
  search_key = {
    hospital_id: User_Details.data.callData.hospital_id
  };
  try {
    const response = await api.post('/video/hospital-configuration-new', search_key);
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
    // throw error;
  }
};

const themeConfiguration = async (User_Details: any) => {
  let search_key;
  search_key = {
    hospital_id: User_Details.data.callData.hospital_id
  };
  try {
    const response = await api.post('/video/theme-configuration-new', search_key);
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
    // throw error;
  }
};

const theme = async (hospital_id: any) => {
  let search_key;
  search_key = {
    hospital_id: hospital_id
  };
  try {
    const response = await api.post('/video/theme-configuration-new', search_key);
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
    // throw error;
  }
};

const GetUrlInput = async (topic: any, DoctorID: any) => {
  let search_key;
  search_key = {
    meeting_id: topic,
    doctor_id: DoctorID
  };
  try {
    const response = await api.post('doctor/meeting-details', search_key);
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
};

const GetUrl = async (data:any) => {
  let search_key;
  search_key = {
    doctor_id : data.data.doctor_id,
    call_id: data.data.call_id,
    meeting_id: data.data.meeting_id
  };
  try {
    const response = await api.post('/doctor/new-doc-arrived', search_key);
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
};

const waitingList = async (User_Details: any, fcmToken: any) => {
  let search_key;
  if (User_Details !== '') {
    search_key = {
      to_user_id: User_Details,
      status: 'busy',
      fcm_token: fcmToken
    };
    try {
      const response = await api.post('/video/new-waiting-list', search_key);
      return response.data;
    } catch (error) {
      console.error('Error fetching posts:', error);
      // throw error;
    }
  }
};

const GetDocStatus = async () => {

  const urlParams = new URLSearchParams(window.location.search);
  const DoctorID = urlParams.get('DoctorID') ?? '';
  const callID = urlParams.get('callID') ?? '';

  let search_key;
  search_key = {
    doctor_id : DoctorID,
  call_id: callID
  };
  try {
    const response = await api.post('/doctor/new-doc-available-status', search_key);
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
    // throw error;
  }
};

const questionAnswer = async (User_Details: any, editableAnswers: any, question: any) => {
  let search_key;
  search_key = {
    id: User_Details.data.callData.id,
    items: Object.entries(editableAnswers).map(([id, value]) => ({
      answer_data: {
        question_type_id: '',
        question_id: id,
        answer: value
      }
    }))
  };
  try {
    const response = await api.post('video/answer-update', search_key);
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
    // throw error;
  }
};

const icd = async () => {
  try {
    const response = await api.get('/general/icd-list');
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
    // throw error;
  }
};

const getUserID = async () => {
  const callID = await apiService.GetCallID();
  const urlParams = new URLSearchParams(window.location.search);
  const userRole = urlParams.get('user_role') ?? ''; 
  const doctorID = urlParams.get('doctorID') ?? ''; 
  try {
    const response = await api.get(
      `/video/view?id=${callID.data.call_detail_id}&user_id=${
        userRole === 'doctor' ? doctorID : window.sessionStorage.getItem('patientUserID') || ''
      }`
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
};

const viewId = async (callID: any, userID: any) => {
  const response = await api.get(`/video/view?id=${callID}${userID === null ? '' : `&user_id=${userID}`}`);
};

const viewUser = async () => {
  const callID = await apiService.GetCallID();
  const UserDetailsID = await apiService.getUserID();
   window.sessionStorage.setItem('patientUserID', UserDetailsID.data.callData.from_user_id);
  const urlParams = new URLSearchParams(window.location.search);
  const userRole = urlParams.get('user_role') ?? ''; // Use '' as default value if null
 
  
  if (callID.data.call_detail_id !== '') {
    try {

      const response = await api.get(
        `/video/view?id=${callID.data.call_detail_id}${
          !UserDetailsID.data.callData.to_user_id
            ? ''
            : `&user_id=${
                userRole === 'doctor'
                  ? UserDetailsID.data.callData.to_user_id
                  : UserDetailsID.data.callData.from_user_id
              }`
        }`
      );
      return response.data;
    } catch (error) {
      console.error('Error fetching posts:', error);
      // throw error;
    }
  } else {
    const urlParams = new URLSearchParams(window.location.search);
    const topic = urlParams.get('topic') ?? ''; // Use '' as default value if null
    const callID = urlParams.get('callID') ?? ''; // Use '' as default value if null
    if (callID !== '') {
      try {
        const response = await api.get(`/video/view?id=${callID}`);
        return response.data;
      } catch (error) {
        console.error('Error fetching posts:', error);
        // throw error;
      }
    }
  }
};

// const viewUserNew = async (callID: any) => {
//   try {
//     const response = await api.get(`/video/view?id=${callID}`);
//     return response.data;
//   } catch (error) {
//     console.error('Error fetching posts:', error);
//     // throw error;
//   }
// };

const appointmentInfo = async (meetID: any) => {
  try {
    const response = await api.get(`/video/view-appointment-details?meeting_id=${meetID}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
    // throw error;
  }
};

const GetDoctors = async () => {
  try {
    const response = await api.get(`/doctor/available-doctors`);
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
    // throw error;
  }
};

const GetAllDoctors = async () => {
  try {
    const response = await api.post(`/doctor/doc-list`);
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
    // throw error;
  }
};

const ViewDocument = async (id: any) => {
  try {
    const response = await api.get(`/patient/down-pdf?id=${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
    // throw error;
  }
};

const GetCallID = async () => {
  const urlParams = new URLSearchParams(window.location.search);
  const meetID = urlParams.get('meetID') ?? ''; // Use '' as default value if null
  const callID = urlParams.get('callID') ?? '';
  if (meetID !== '') {
    let search_key;
    search_key = {
      meeting_id: meetID
    };

    try {
      const response = await api.post('video/get-calldetailid', search_key);
      return response.data;
    } catch (error) {
      console.error('Error fetching posts:', error);
      // throw error;
    }
  } else if (callID !== '') {
    let search_key;
    search_key = {
      meeting_id: callID
    };
    try {
      const response = await api.post('video/get-calldetailid', search_key);
      return response.data;
    } catch (error) {
      console.error('Error fetching posts:', error);
      // throw error;
    }
  }
};

const CallForward = async (User_Details: any, forwardValue: any) => {
  let search_key;
  search_key = {
    doctor_id: User_Details.user_id,
    patient_id: forwardValue.from_user_id,
    call_id: forwardValue.id
  };
  try {
    const response = await api.post('/video/call-forward', search_key);
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
    // throw error;
  }
};

const CallForwardUser = async (User_Details: any, forwardValue: any) => {
  const urlParams = new URLSearchParams(window.location.search);
  const doctorID = urlParams.get('doctorID') ?? ''; 
  let requestbody;
  requestbody = {
    doctor_id: User_Details.user_id,
    patient_id: forwardValue.data.callData.from_user_id,
    call_id: forwardValue.data.callData.id,
    current_doctor_id : doctorID
  };
  try {
    const response = await api.post('/video/call-forward', requestbody);
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
    // throw error;
  }
};

const JoinCallUpdate = async (User_Details: any) => {
  let search_key;
  search_key = {
    doctor_id: User_Details.to_user_id,
    patient_id: User_Details.from_user_id,
    call_id: User_Details.id
  };
  try {
    const response = await api.post('doctor/join-call', search_key);
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
    // throw error;
  }
};

const InviteDoc = async (doctor: any, meetingID: any) => {
  let search_key;
  search_key = {
    hospita_id: doctor.hospital_id,
    meeting_id: meetingID,
    doctor_id : doctor.user_id
  };
  try {
    const response = await api.post('video/invite-doctor', search_key);
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
    // throw error;
  }
};

const ZoomRecording = async () => {
  const callID = await apiService.GetCallID();

  let search_key;
  search_key = {
    call_id: callID.data.call_detail_id,
    session_id: window.sessionStorage.getItem('sessionId')
  };
  try {
    const response = await api.post('video/save-zoom-session', search_key);
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
    // throw error;
  }
};

const chatHistory = async (User_Details: any) => {
  let search_key;
  search_key = {
    InstantChat: {
      sender_id: User_Details.data.callData.from_user_id,
      receiver_id: User_Details.data.callData.to_user_id,
      chat_from: 'chat',
      calldetail_id: User_Details.data.callData.id
    }
  };

  try {
    const response = await api.post('/chat/history-new', search_key);
    return response.data;
  } catch (error) {
    console.error('Error creating post:', error);
    // throw error;
  }
};

const drugList = async (drug: any) => {
  let search_key;
  search_key = {
    search_key: drug
  };

  try {
    const response = await api.post('/general/drug-list', search_key);
    return response.data;
  } catch (error) {
    console.error('Error creating post:', error);
    // throw error;
  }
};

const icdList = async (selectValue: any) => {
  let search_key;
  search_key = {
    search_key: selectValue
  };

  try {
    const response = await api.post('/general/icd-code-list', search_key);
    return response.data;
  } catch (error) {
    console.error('Error creating post:', error);
    // throw error;
  }
};

const updateCallTime = async (selectValue: any) => {
  let search_key;
  search_key = {
    id: selectValue
  };

  try {
    const response = await api.post('/video/new-update-call-time', search_key);
    return response.data;
  } catch (error) {
    console.error('Error creating post:', error);
    // throw error;
  }
};

const endCallTime = async (selectValue: any) => {
  let search_key;
  search_key = {
    id: selectValue
  };

  try {
    const response = await api.post('/video/new-end-call', search_key);
    return response.data;
  } catch (error) {
    console.error('Error creating post:', error);
    // throw error;
  }
};

const docViewUser = async (doctorID: any) => {
  let search_key;
  search_key = {
    id: doctorID
  };

  try {
    const response = await api.post('/doctor/doc-view-new', search_key);
    return response.data;
  } catch (error) {
    console.error('Error creating post:', error);
    // throw error;
  }
};

const patViewUser = async (patientID: any) => {
  let search_key;
  search_key = {
    id: patientID
  };

  try {
    const response = await api.post('patient/pat-view', search_key);
    return response.data;
  } catch (error) {
    console.error('Error creating post:', error);
    // throw error;
  }
};

const hosLogo = async (hospital: any) => {
  let search_key;
  search_key = {
    hospital_id: hospital
  };

  try {
    const response = await api.post('/general/hospital-logo', search_key);
    return response.data;
  } catch (error) {
    console.error('Error creating post:', error);
    // throw error;
  }
};

// const savePrecall = async (
//   nose: any,
//   reasonForVisit: any,
//   symptoms: any,
//   symptomDuration: any,
//   report: any,
//   userDetails: any
// ) => {
//   let search_key;
//   search_key = {
//     CallDetail: {
//       to_user_id: userDetails.data.callData.to_user_id,
//       patient_profile_id: userDetails.data.callData.patient_profile_id,
//       from_user_id: userDetails.data.callData.from_user_id,
//       hospital_id: userDetails.data.callData.hospital_id,
//       hos_speciality_data: [
//         {
//           id: userDetails.data.callData.hos_speciality_data.id
//         }
//       ],
//       pre_call: {
//         questionType: [
//           {
//             id: 'fc50f0c7-8008-926e-e911-7e65008fc00e',
//             name: 'Reason For Visit',
//             sort_order: 0,
//             webicon_file: '',
//             mobicon_file: '',
//             questions: [
//               {
//                 id: '08ba7802-6fb0-1a68-84c0-5e73e49fa003',
//                 hos_spec_id: 'fc50f0c7-8008-926e-e911-7e65008fc00e',
//                 name: 'What is your reason for the visit?',
//                 label: 'Reason',
//                 default_value: '',
//                 input_type: 'textbox',
//                 placeholder: '',
//                 is_required: 'no',
//                 answer: reasonForVisit
//               },
//               {
//                 id: '86bd0da9-1263-bdc7-e18e-5014a4bfb387',
//                 hos_spec_id: 'fc50f0c7-8008-926e-e911-7e65008fc00e',
//                 name: 'Are you having running nose?',
//                 label: 'Running nose',
//                 default_value: '',
//                 input_type: 'textbox',
//                 placeholder: '',
//                 is_required: 'no',
//                 answer: nose
//               }
//             ]
//           },
//           {
//             id: 'ca629bd6-6a11-d800-8817-1a97d75ff060',
//             name: 'Symptoms',
//             sort_order: 0,
//             webicon_file: '',
//             mobicon_file: '',
//             questions: [
//               {
//                 id: '92dfb3eb-c769-e79a-326e-861ae582d378',
//                 hos_spec_id: 'ca629bd6-6a11-d800-8817-1a97d75ff060',
//                 name: 'What is your symptoms?',
//                 label: 'Symptoms',
//                 default_value: '',
//                 input_type: 'textbox',
//                 placeholder: '',
//                 is_required: 'no',
//                 answer: symptoms
//               },
//               {
//                 id: '745fb82c-e5d1-52b1-40d1-9ff9302ce617',
//                 hos_spec_id: 'ca629bd6-6a11-d800-8817-1a97d75ff060',
//                 name: 'How long do you have this symptom?',
//                 label: 'How long',
//                 default_value: '',
//                 input_type: 'textbox',
//                 placeholder: '',
//                 is_required: 'no',
//                 answer: symptomDuration
//               }
//             ]
//           }
//         ]
//       }
//     }
//   };

//   try {
//     const response = await api.post('/video/save-pre-call-details', search_key);
//     return response.data;
//   } catch (error) {
//     console.error('Error creating post:', error);
//     //throw error;
//   }
// };

const saveNotes = async (
  prescriptions: any,
  absenceData: any,
  codes: any,
  UserDetails: any,
  selectedIdsFormatted: any,
  physician: any,
  physicianNotes: any,
  editablePresNotes: any,
  editableRefNotes: any
   
) => {
  let search_key;
  search_key = {
    CallDetail: {
      id: UserDetails.data.callData.id,
      hospital_id: UserDetails.data.callData.hospital_id,
      practitioner_notes: window.sessionStorage.getItem('Practitioner'),
      referal_notes: window.sessionStorage.getItem('FollowUp'),
      documentation_notes: '',
      is_published: window.sessionStorage.getItem('publish'),
      identified_patient: 'on',
      zoom_session_id: window.sessionStorage.getItem('sessionId')
    },
    CallAbsenceForm: absenceData,
    CallSpecialityreferralForm: {
      physician: 'physician',
      notes: 'notes'
    },
    CallLabTests: selectedIdsFormatted,
    CallIcdCode: codes,
    CallPrescription: prescriptions
  };

  try {
    const response = await api_.post('/video/save-notes-new', search_key);
    return response.data;
  } catch (error) {
    console.error('Error creating post:', error);
    // throw error;
  }
};

const LeaveRoom = async (id: any) => {
  let search_key;
  search_key = {
    callID: id
  };

  try {
    const response = await api_.post('/video/leave-waiting-room', search_key);
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
    // throw error;
  }
};

const LeaveRoomDoc = async (id: any) => {
  let search_key;
  search_key = {
    doctor_id: id
  };

  try {
    const response = await api_.post('/doctor/doctor-status-change', search_key);
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
    // throw error;
  }
};

const LeaveRoomStatusChange = async (id: any, callID: any) => {
  let search_key;
  search_key = {
    user_id : id,
    callid : callID,
}

  try {
    const response = await api_.post('/video/leave-room', search_key);
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
    // throw error;
  }
};

const DocStatusChange = async (id: any) => {
  let search_key;
  search_key = {
    doctor_id: id
  };

  try {
    const response = await api_.post('/doctor/doctor-status-change-new', search_key);
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
    // throw error;
  }
};
const apiService = {
  sendMessage,
  sendMessagePat,
  clinicalOrder,
  viewUser,
  // viewUserNew,
  drugList,
  icdList,
  // savePrecall,
  chatHistory,
  icd,
  saveNotes,
  videoHistory,
  hospitalConfiguration,
  waitingList,
  themeConfiguration,
  questionAnswer,
  sendAttachmentDoc,
  sendAttachmentPat,
  GetCallID,
  JoinCallUpdate,
  GetDoctors,
  CallForward,
  CallForwardUser,
  ViewDocument,
  updateCallTime,
  endCallTime,
  docViewUser,
  hosLogo,
  LeaveRoom,
  LeaveRoomDoc,
  appointmentInfo,
  ZoomRecording,
  viewId,
  getUserID,
  LeaveRoomStatusChange,
  patViewUser,
  GetDocStatus,
  GetUrl,
  GetUrlInput,
  DocStatusChange,
  theme,
  GetAllDoctors,
  InviteDoc,
};

export default apiService;
