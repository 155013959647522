import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyCghOXratolTmDLNt6eNT6KV4qX1uBbIXQ',

  authDomain: 'web-push-notification-1fa4f.firebaseapp.com/',

  projectId: 'web-push-notification-1fa4f',

  storageBucket: 'web-push-notification-1fa4f.appspot.com',

  messagingSenderId: '459929656417',

  appId: '1:459929656417:web:23d38250d226734a7e1e20',

  measurementId: 'G-S8M388663F'
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

export default firebaseApp;
