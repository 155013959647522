// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-receiver {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  height: 25px;
  margin: 15px 0;
}
.chat-receiver .chat-to {
  margin-right: 5px;
}

.chat-receiver-item {
  padding-left: 30px;
}
.chat-receiver-item.selected {
  padding-left: 10px;
}
.chat-receiver-item .chat-receiver-item-affix {
  color: #999;
  margin-left: 5px;
}

.chat-privilege-item {
  padding-left: 25px;
}
.chat-privilege-item.selected {
  padding-left: 5px;
}`, "",{"version":3,"sources":["webpack://./src/feature/command/component/cmd-receiver.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,eAAA;EACA,YAAA;EACA,cAAA;AACF;AAAE;EACE,iBAAA;AAEJ;;AAGA;EACE,kBAAA;AAAF;AACE;EACE,kBAAA;AACJ;AACE;EACE,WAAA;EACA,gBAAA;AACJ;;AAEA;EACE,kBAAA;AACF;AAAE;EACE,iBAAA;AAEJ","sourcesContent":[".chat-receiver {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 0 20px;\n  height: 25px;\n  margin: 15px 0;\n  .chat-to {\n    margin-right: 5px;\n  }\n  \n}\n\n.chat-receiver-item{\n  padding-left: 30px;\n  &.selected {\n    padding-left: 10px;\n  }\n  .chat-receiver-item-affix{\n    color:#999;\n    margin-left: 5px;\n  }\n}\n.chat-privilege-item {\n  padding-left: 25px;\n  &.selected {\n    padding-left: 5px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
