import React, { useState, useEffect } from 'react';
import { Button, Badge } from 'antd';
import classNames from 'classnames';
import { IconFont } from '../../../component/icon-font';
import ReadMessage from './../ReadMessage'; // Adjust the import path as per your project structure

interface ChatButtonProps {
    onChatClick: () => void;
    senderId?: any;
    chats?: any;
}

const ChatButton: React.FC<ChatButtonProps> = (props) => {
    const { onChatClick, senderId, chats } = props;
    const [unreadCount, setUnreadCount] = useState<number | null>(0);
    const [currentUserId, setCurrentUserId] = useState<number | null>(null);



    console.log(chats);
    console.log(senderId);


    useEffect(() => {


        const fetchData = async () => {

            if (chats.length > 0) {

                let receiver_idxx = '';


                const chat = chats[0];
                if (chat.sender_id === senderId) {
                    receiver_idxx = chat.receiver_id;
                } else {
                    receiver_idxx = chat.sender_id;
                }

                console.log(senderId);




                try {

                    if (receiver_idxx !== "") {
                        const response = await fetch('https://qa-uaesaas-api.instapract.ae/web/api/chat/read-message', {
                            method: 'POST',
                            headers: {
                                'Authorization': 'Bearer C0j_Q',
                                'APPID': 'APPN7MIHT9',
                                'Content-Type': 'application/json',
                                'Cookie': 'PHPSESSID=88bc0bfde1507d9b47d5132f35ce9654'
                            },
                            body: JSON.stringify({
                                InstantChat: {
                                    sender_id: senderId,
                                    receiver_id: window.sessionStorage.getItem('patientUserID')

                                }
                            })
                        });

                        if (!response.ok) {
                            throw new Error(`Error: ${response.statusText}`);
                        }

                        const data = await response.json();
                        setUnreadCount(data.length);

                    }

                } catch (error) {
                    console.error('Error fetching data:', error);
                }


            };

        }

        // Initial fetch when component mounts
        fetchData();

        // Set up interval to fetch data every 5 seconds
        const intervalId = setInterval(fetchData, 5000);

        // Clean up interval on component unmount
        return () => clearInterval(intervalId);
    }, []);


const [isHovered, setIsHovered] = useState(false);

    return (
        <Badge count={chats?.length || 0}  style={{ transform: 'translate(-20%, -30%)', backgroundColor: 'var(--theme-bg)'}}>
        <Button
            className={classNames('vc-button')}
            icon={<IconFont type="icon-chat"  style={{
                color: isHovered ? 'var(--theme-bg)' : 'grey', 
              }} />}
            ghost={true}
            shape="circle"
            size="large"
            onClick={onChatClick}
            onMouseEnter={() => setIsHovered(true)} 
            onMouseLeave={() => setIsHovered(false)} 
            title="chat session"
            style={{borderRadius: '20px',
                border: '1px solid rgba(75, 85, 99, 0.5)'}}
        />
    </Badge>
    );
};

export default ChatButton;
