// Header.tsx
import React from 'react';
import { useHeader } from './HeaderContext';

function Header() {
  const { LOGO, user } = useHeader();

  return (
    <nav className="navbar navbar-expand-lg navbar-light custom-body-bg shadow" style={{ backgroundColor: 'white' }}>
      <div className="container">
        <a className="navbar-brand" href="#">
          <img src={LOGO} crossOrigin="anonymous" className='Logo'/>
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0 align-items-center">
            {/* <li className="nav-item" style={{ cursor: 'pointer' }}>

              Leave Room

            </li> */}
            <li className="nav-item">
              {user ? (
                <a className="nav-link" href="#">
                  <div className="user-profile">
                    <img crossOrigin="anonymous" src={user.profile_picture} className="rounded-circle" />
                    <div className="profile">
                      <h4>{user.name ? user.name : user?.display_name ?? user?.display_name}</h4>
                    </div>
                  </div>
                </a>
              ) : (
                <p>...</p>
              )}{' '}
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Header;
