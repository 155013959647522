// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.room-options .ant-menu {
  border-right: 0;
}
.room-options .ant-input-number {
  width: 65px;
  margin: 0 3px;
}
.room-options .ant-menu-item.indent {
  margin-left: 30px;
}`, "",{"version":3,"sources":["webpack://./src/feature/subsession/component/subsession-options.scss"],"names":[],"mappings":"AACE;EACE,eAAA;AAAJ;AAEE;EACE,WAAA;EACA,aAAA;AAAJ;AAEE;EACE,iBAAA;AAAJ","sourcesContent":[".room-options {\n  .ant-menu {\n    border-right: 0;\n  }\n  .ant-input-number {\n    width: 65px;\n    margin: 0 3px;\n  }\n  .ant-menu-item.indent {\n    margin-left: 30px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
