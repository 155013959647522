import { useEffect, useContext, useState, useCallback, useReducer, useMemo } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ZoomVideo, { ConnectionState, ReconnectReason } from '@zoom/videosdk';
import { message, Modal } from 'antd';
import 'antd/dist/antd.min.css';
import produce from 'immer';
import Home from './feature/home/home';
import Doctorhome from './feature/doctorhome/home';
import Doctor from './feature/doctor/video';
import VideoSingle from './feature/doctor/video-single';
import VideoNonSAB from './feature/doctor/video-non-sab';
import Preview from './feature/preview/preview';
import ZoomContext from './context/zoom-context';
import ZoomMediaContext from './context/media-context';
import LoadingLayer from './component/loading-layer';
import Chat from './feature/chat/chat';
import Command from './feature/command/command';
import Subsession from './feature/subsession/subsession';
import { MediaStream } from './index-types';
import './App.css';

import { isAndroidBrowser } from './utils/platform';
  import waitingroom from './feature/waitingroom/waitingroom';
import apiService from './services/api';
interface AppProps {
  meetingArgs: {
    sdkKey: string;
    topic: string;
    signature: string;
    name: string;
    password?: string;
    webEndpoint?: string;
    enforceGalleryView?: string;
    customerJoinId?: string;
    lang?: string;
  };
}
const mediaShape = {
  audio: {
    encode: false,
    decode: false
  },
  video: {
    encode: false,
    decode: false
  },
  share: {
    encode: false,
    decode: false
  }
};
const mediaReducer = produce((draft, action) => {
  switch (action.type) {
    case 'audio-encode': {
      draft.audio.encode = action.payload;
      break;
    }
    case 'audio-decode': {
      draft.audio.decode = action.payload;
      break;
    }
    case 'video-encode': {
      draft.video.encode = action.payload;
      break;
    }
    case 'video-decode': {
      draft.video.decode = action.payload;
      break;
    }
    case 'share-encode': {
      draft.share.encode = action.payload;
      break;
    }
    case 'share-decode': {
      draft.share.decode = action.payload;
      break;
    }
    case 'reset-media': {
      Object.assign(draft, { ...mediaShape });
      break;
    }
    default:
      break;
  }
}, mediaShape);

declare global {
  interface Window {
    webEndpoint: string | undefined;
    zmClient: any | undefined;
    mediaStream: any | undefined;
    crossOriginIsolated: boolean;
    ltClient: any | undefined;
  }
}
const meetingArgs: any = Object.fromEntries(new URLSearchParams(location.search));

function DoctorApp(props: AppProps) {
  const {
    meetingArgs: {
      sdkKey,
      topic,
      signature,
      name,
      password,
      webEndpoint: webEndpointArg,
      enforceGalleryView,
      customerJoinId,
      lang
    }
  } = props;
  const [loading, setIsLoading] = useState<any>(true);
  const [loadingText, setLoadingText] = useState('');
  const [isFailover, setIsFailover] = useState<boolean>(false);
  const [status, setStatus] = useState<string>('closed');
  const [mediaState, dispatch] = useReducer(mediaReducer, mediaShape);
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
  const [isSupportGalleryView, setIsSupportGalleryView] = useState<boolean>(true);
  const zmClient = useContext(ZoomContext);
  let webEndpoint: any;
  if (webEndpointArg) {
    webEndpoint = webEndpointArg;
  } else {
    webEndpoint = window?.webEndpoint ?? 'zoom.us';
  }
  const mediaContext = useMemo(() => ({ ...mediaState, mediaStream }), [mediaState, mediaStream]);
  const galleryViewWithoutSAB = Number(enforceGalleryView) === 1 && !window.crossOriginIsolated;

  useEffect(() => {
    const init = async () => {
      log('Initializing Zoom Video SDK...');
      await zmClient.init('en-US', `${window.location.origin}/lib`, {
        webEndpoint,
        enforceMultipleVideos: galleryViewWithoutSAB,
        enforceVirtualBackground: galleryViewWithoutSAB,
        stayAwake: true,
        leaveOnPageUnload: true
      });
      try {
        log('Joining session...');
        setLoadingText('Joining the session...');
        await zmClient.join(topic, signature, name, password).catch((e) => {
          console.log(e);
        });
        const stream = zmClient.getMediaStream();
        setMediaStream(stream);
        setIsSupportGalleryView(stream.isSupportMultipleVideos());
        setIsLoading(false);
        log('Successfully joined session');
      } catch (e: any) {
        setIsLoading(false);
        // message.error(e.reason);
      }
    };
    init();
    return () => {
      ZoomVideo.destroyClient();
    };
  }, [sdkKey, signature, zmClient, topic, name, password, webEndpoint, galleryViewWithoutSAB, customerJoinId]);
  const onConnectionChange = useCallback(
    (payload) => {
      log('Connection change detected:', payload);
      if (payload.state === ConnectionState.Reconnecting) {
        setIsLoading(true);
        setIsFailover(true);
        setStatus('connecting');
        const { reason, subsessionName } = payload;
        if (reason === ReconnectReason.Failover) {
          setLoadingText('Session Disconnected,Try to reconnect');
        } else if (reason === ReconnectReason.JoinSubsession || reason === ReconnectReason.MoveToSubsession) {
          setLoadingText(`Joining ${subsessionName}...`);
          log(`Joining ${subsessionName}...`);
        } else if (reason === ReconnectReason.BackToMainSession) {
          setLoadingText('Returning to Main Session...');
        }
      } else if (payload.state === ConnectionState.Connected) {
        setStatus('connected');
        log('Connection established.');
        if (isFailover) {
          setIsLoading(false);
        }
        window.zmClient = zmClient;
        window.mediaStream = zmClient.getMediaStream();

        console.log('getSessionInfo', zmClient.getSessionInfo());
        console.log('getSessionInfosessionId', zmClient.getSessionInfo().sessionId);
        window.sessionStorage.setItem('sessionId', zmClient.getSessionInfo().sessionId);
      } else if (payload.state === ConnectionState.Closed) {
        setStatus('closed');
        dispatch({ type: 'reset-media' });
        if (payload.reason === 'ended by host') {
          Modal.warning({
            title: 'Meeting ended',
            content: 'This meeting has been ended by host'
          });
        }
      }
    },
    [isFailover, zmClient]
  );
  const onMediaSDKChange = useCallback((payload) => {
    const { action, type, result } = payload;
    dispatch({ type: `${type}-${action}`, payload: result === 'success' });
  }, []);

  const onDialoutChange = useCallback((payload) => {
    console.log('onDialoutChange', payload);
  }, []);

  const onAudioMerged = useCallback((payload) => {
    console.log('onAudioMerged', payload);
  }, []);

  const onLeaveOrJoinSession = useCallback(async () => {
    if (status === 'closed') {
      setIsLoading(true);
      await zmClient.join(topic, signature, name, password);
      setIsLoading(false);
    } else if (status === 'connected') {
      log('Leaving session...');
      await zmClient.leave();
      log('Left session successfully.');
      message.warn('You have left the session.');
    }
  }, [zmClient, status, topic, signature, name, password]);
  useEffect(() => {
    zmClient.on('connection-change', onConnectionChange);
    zmClient.on('media-sdk-change', onMediaSDKChange);
    zmClient.on('dialout-state-change', onDialoutChange);
    zmClient.on('merged-audio', onAudioMerged);
    return () => {
      zmClient.off('connection-change', onConnectionChange);
      zmClient.off('media-sdk-change', onMediaSDKChange);
      zmClient.off('dialout-state-change', onDialoutChange);
      zmClient.off('merged-audio', onAudioMerged);
    };
  }, [zmClient, onConnectionChange, onMediaSDKChange, onDialoutChange, onAudioMerged]);
  window.sessionStorage.setItem('loading',loading)

  if (window?.crossOriginIsolated) {
    meetingArgs.enforceGalleryView = true;
  }
  

  const log = async (message: string, data?: any) => {

    const urlParams = new URLSearchParams(window.location.search);
    const meetingID = urlParams.get('meetingID') ?? '';
    const logData = {
      message,
      data,
      timestamp: new Date(),
      userAgent: navigator.userAgent,
      platform: navigator.platform,
      sessionId:meetingID,
    };
    await apiService.SendLog(logData)
  };
  
  // const log = (message: string, data?: any) => {
  //   // Customize logging behavior (e.g., console, server, file)
  //   console.log(`[DoctorApp] ${message}`, data);
  // };
  
  return (
    <div className="App">
      {/* {loading && <LoadingLayer content={loadingText} />} */}
      {/* {!loading && (
        <ZoomMediaContext.Provider value={mediaContext}>
          <Router>
            <Switch>
              <Route
                path="/"
                render={(props) => (
                  <Doctorhome {...props} status={status} onLeaveOrJoinSession={onLeaveOrJoinSession} />
                )}
                exact
              />
              <Route path="/index.html" component={Home} exact />
              <Route path="/chat" component={Chat} />
              <Route path="/command" component={Command} />

              <Route
                path="/doctor"
                component={isSupportGalleryView ? Doctor : galleryViewWithoutSAB ? VideoNonSAB : VideoSingle}
              />
              <Route path="/subsession" component={Subsession} />
              <Route path="/preview" component={Preview} />
              <Route path="/preview" component={Preview} />
              <Route path="/waitingroom" component={waitingroom} />
            </Switch>
          </Router>
        </ZoomMediaContext.Provider>
      )} */}

<ZoomMediaContext.Provider value={mediaContext}>
          <Router>
            <Switch>
              <Route
                path="/"
                render={(props) => (
                  <Doctorhome {...props} status={status} onLeaveOrJoinSession={onLeaveOrJoinSession} />
                )}
                exact
              />
              <Route path="/index.html" component={Home} exact />
              <Route path="/chat" component={Chat} />
              <Route path="/command" component={Command} />

              <Route
                path="/doctor"
                component={isSupportGalleryView ? Doctor : galleryViewWithoutSAB ? VideoNonSAB : VideoSingle}
              />
              <Route path="/subsession" component={Subsession} />
              <Route path="/preview" component={Preview} />
            </Switch>
          </Router>
        </ZoomMediaContext.Provider> 

    </div>
  );
}

export default DoctorApp;
